import React, { useContext, useState } from 'react';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useForm } from '../../../shared/hooks/form-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import { VALIDATOR_REQUIRE } from '../../../shared/util/validators';

const UpdateAccountPassword = () => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [successMsg, setSuccessMsg] = useState(false);

    const [formState, inputHandler] = useForm({
        passwordCurrent: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        },
        passwordConfirm: {
            value: '',
            isValid: false
        },
    }, false);

    const updateAccountPasswordHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users/updateMyPassword', 'PATCH', JSON.stringify({
                passwordCurrent: formState.inputs.passwordCurrent.value,
                password: formState.inputs.password.value,
                passwordConfirm: formState.inputs.passwordConfirm.value,
                user: {
                    id: auth.userId
                }
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <form onSubmit={updateAccountPasswordHandler}>
            <div className="row">
                <Input
                    id="passwordCurrent"
                    type="password"
                    element="input"
                    label="Current Password"
                    errorText="Please enter your old password."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                <Input
                    id="password"
                    type="password"
                    element="input"
                    label="New Password"
                    errorText="Please enter new a password."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                <Input
                    id="passwordConfirm"
                    type="password"
                    element="input"
                    label="Verify New Password"
                    errorText="Passwords do not match."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Password was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Now</Button>
            </div>
        </form>
    )
}

export default UpdateAccountPassword;

