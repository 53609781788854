import React, { useState, useEffect } from 'react';
import { useHistory, useLocation  } from 'react-router-dom';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
import Modal from '../../../shared/components/UIElements/Modal';
import ElectronicConsent from '../../BuyShares/ElectronicConsent';
import NdaDocument from '../../Policies/NdaDocument';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinnder from '../../../shared/components/UIElements/LoadingSpinner';
// import { AuthContext } from '../../../shared/context/auth-context';

import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EQUAL } from '../../../shared/util/validators';
import { useForm } from '../../../shared/hooks/form-hook';
import { useHttpClient } from '../../../shared/hooks/http-hook';

import './AddInvestor.scss';

const AddInvestor = props => {

    const { sendRequest } = useHttpClient();
    // const auth = useContext(AuthContext);
    const [isChecked, setIsChecked] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [elConsModal, setElConsModal] = useState(false);
    const [ndaModal, setNdaModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();
    // const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const query = useLocation();

    useEffect(() => {
        if (query.search === '?mode=admin') {
            setIsAdmin(true);
        }
    }, [query])


    const [formState, inputHandler] = useForm({
        agreementCheckBox: {
            value: '',
            isValid: false
        },
        firstName: {
            value: '',
            isValid: false
        },
        middleName: {
            value: '',
            isValid: false
        },
        lastName: {
            value: '',
            isValid: false
        },
        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        },
        passwordConfirm: {
            value: '',
            isValid: false
        },
        subscriberType:{
            value: '',
            isValid: false
        }
    }, false);


    const toggleFormReady = (event) => {
        setIsChecked(!isChecked)
    }

    const createAcccountHandler = async () => {

        const accountId = `${formState.inputs.firstName.value.charAt(0)}${formState.inputs.lastName.value.charAt(0)}-${Date.now()}`;

        const accountData = {
            agreementCheckBox: isChecked,
            firstName: formState.inputs.firstName.value,
            middleName: formState.inputs.middleName.value,
            lastName: formState.inputs.lastName.value,
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
            passwordConfirm: formState.inputs.passwordConfirm.value,
            subscriberType: formState.inputs.subscriberType.value,
            accountId: accountId,
            
        }

        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users/signup', 'POST', JSON.stringify(accountData), {
                'Content-Type': 'application/json'
            });

            history.push('/admin/investors');

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (formState.isValid && isChecked) {
            setFormReady(true);
        }
    }, [formState, isChecked])


    const openElConsModal = () => {
        setElConsModal(true)
    }

    const closeElConsModal = () => {
        setElConsModal(false)
    }

    const openNdaModal = () => {
        setNdaModal(true)
    }

    const closeNdaModal = () => {
        setNdaModal(false)
    }

    // if (!props.active) {
    //     return null;
    // }

    return (

        <div className="create-account">
            <Modal
                show={elConsModal}
                onCancel={closeElConsModal}
                header="Consent to E-Signature"
                size="large"
            >
                <ElectronicConsent
                    close={closeElConsModal}
                    isCreateAccount
                />
            </Modal>

            <Modal
                show={ndaModal}
                onCancel={closeNdaModal}
                header="NDA  Agreement"
                size="large"
            >
                <NdaDocument
                    close={closeNdaModal}
                    isCreateAccount
                    partyName={formState.inputs.firstName.value + ' ' +  formState.inputs.lastName.value}
                />
            </Modal>

            <div className="create-account-content">
                <div className="create-account-content-box">
                    <div className="row" style={{ paddingTop: "2rem" }}>
                        <Input
                            id="subscriberType"
                            element="select"
                            label="Investing As:"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        >
                            <option value="select-investors-type">Choose one...</option>
                            <option value="individual">Individual</option>
                            <option value="corporation">Corporation</option>
                            <option value="limited-partnership">Limited Partnership</option>
                            <option value="general-partnership">General Partnership</option>
                            <option value="revocable-trust">Revocable Trust</option>
                            <option value="other">Other</option>
                        </Input>
                    </div>

                    <label className="create-account-content-box-label">Account Information</label>

                    <div className="row">
                        <Input
                            id="firstName"
                            type="text"
                            element="input"
                            label="First Name"
                            errorText="Please enter your first name."
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                        />

                        <Input
                            id="middleName"
                            type="text"
                            element="input"
                            label="Middle Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="lastName"
                            type="text"
                            element="input"
                            label="Last Name"
                            errorText="Please enter your last name."
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                        />
                    </div>

                    <div className="row">

                        <Input
                            id="email"
                            type="email"
                            element="input"
                            label="Email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email address."
                            onInput={inputHandler}
                        />

                        <Input
                            id="password"
                            type="password"
                            element="input"
                            label="Password"
                            validators={[VALIDATOR_MINLENGTH(8), VALIDATOR_REQUIRE()]}
                            errorText="Please enter a password."
                            onInput={inputHandler}
                        />

                        <Input
                            id="passwordConfirm"
                            type="password"
                            element="input"
                            label="Verify Password"
                            validators={[VALIDATOR_EQUAL(formState.inputs.password.value), VALIDATOR_REQUIRE()]}
                            errorText={"Passwords don't match."}
                            onInput={inputHandler}
                        />
                    </div>

                    <div className="accreditation-acceptance" style={{ marginTop: "2rem" }}>
                        <Input
                            id="agreementCheckBox"
                            type="checkbox"
                            element="checkbox"
                            label={<>By checking this box you agree with the<Button onClick={openNdaModal} size="link" >Followone NDA</Button>the 
                                <Button onClick={openElConsModal} size="link" >Consent and to Electronic Signatures</Button> 
                                and Records in Followone Transactions and digitaly sign the NDA using your First and Last Name.</>}
                            onInput={inputHandler}
                            actionChange={(event) => toggleFormReady(event)}
                            validators={[]}
                        />
                    </div>

                    <div className="button-holder-previous">
                    </div>

                    <div className="button-holder-next">
                        <Button disabled={!formReady && !isAdmin} onClick={createAcccountHandler}>Create Account</Button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddInvestor;