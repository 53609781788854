import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../../shared/components/UIElements/Input';
import Button from '../../../../shared/components/UIElements/Button';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { useForm } from '../../../../shared/hooks/form-hook';
import { AuthContext } from '../../../../shared/context/auth-context';


const SetPrivateShareSellingRules = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    const [appDetails, setAppDetails] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const [formState, inputHandler] = useForm({
        maxSharesAllowedForSale: {
            value: '',
            isValid: false
        },
        privatePricePerShare: {
            value: '',
            isValid: false
        },
        enterpriseValue: {
            value: '',
            isValid: false,
        },
        bankName: {
            value: '',
            isValid: false
        },
        aba: {
            value: '',
            isValid: false
        },
        account: {
            value: '',
            isValid: false
        },
        accountName: {
            value: '',
            isValid: false
        },
        to: {
            value: '',
            isValid: false
        },
        street: {
            value: '',
            isValid: false
        },
        city: {
            value: '',
            isValid: false
        },
        zip: {
            value: '',
            isValid: false
        },
        state: {
            value: '',
            isValid: false
        },
    }, false);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setAppDetails(responseData.data.data[0]);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();
    }, [sendRequest])

    const privateShareSellingSubmitHandler = async event => {
        event.preventDefault();

        const paymentWireTransfer = {
            bankName: formState.inputs.bankName.value,
            aba: formState.inputs.aba.value,
            account: formState.inputs.account.value,
            accountName: formState.inputs.accountName.value
        }

        const paymentAddress = {
            to: formState.inputs.to.value,
            street: formState.inputs.street.value,
            city: formState.inputs.city.value,
            zip: formState.inputs.zip.value,
            state: formState.inputs.state.value
        }

        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/app/${appDetails._id}`, 'PATCH', JSON.stringify({
                maxSharesAllowedForSale: formState.inputs.maxSharesAllowedForSale.value,
                privatePricePerShare: formState.inputs.privatePricePerShare.value,
                enterpriseValue: formState.inputs.enterpriseValue.value,
                paymentWireTransfer: paymentWireTransfer,
                paymentAddress: paymentAddress
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }

    if (!appDetails) {
        return null;
    }

    return (
        <form onSubmit={privateShareSellingSubmitHandler}>
            <div className="row">
                <Input
                    id="maxSharesAllowedForSale"
                    type="number"
                    element="input"
                    label="Max. Shares % Allowed for Sale"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.maxSharesAllowedForSale}
                />

                <Input
                    id="privatePricePerShare"
                    type="number"
                    element="input"
                    label="Private Price / Share"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.privatePricePerShare}
                />

                <Input
                    id="enterpriseValue"
                    type="number"
                    element="input"
                    label="Enterprise Value"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.enterpriseValue}
                />
            </div>



            <div className="row">
                <Input
                    id="bankName"
                    type="text"
                    element="input"
                    label="Bank Name"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentWireTransfer.bankName}
                />
                <Input
                    id="aba"
                    type="number"
                    element="input"
                    label="Routing/ABA Number"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentWireTransfer.aba}
                />
                <Input
                    id="accountName"
                    type="text"
                    element="input"
                    label="Account Name"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentWireTransfer.accountName}
                />
                <Input
                    id="account"
                    type="number"
                    element="input"
                    label="Account Number"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentWireTransfer.account}
                />
            </div>

            <div className="row">
                <Input
                    id="to"
                    type="text"
                    element="input"
                    label="Payment To"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentAddress.to}
                />
                <Input
                    id="street"
                    type="text"
                    element="input"
                    label="Street"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentAddress.street}
                />
                <Input
                    id="city"
                    type="text"
                    element="input"
                    label="City"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentAddress.city}
                />
                <Input
                    id="state"
                    type="text"
                    element="input"
                    label="State"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentAddress.state}
                />
                <Input
                    id="zip"
                    type="text"
                    element="input"
                    label="Zip Code"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={appDetails.paymentAddress.zip}
                />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Other settings updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Now</Button>
            </div>
        </form>
    )
}

export default SetPrivateShareSellingRules;