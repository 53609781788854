// import React, { useContext } from 'react';

import classes from './Footer.module.scss';

import followoneImg from '../../../assets/followone-logo.png';
import appleImg from '../../../assets/apple.png';
import playstoreImg from '../../../assets/playstore.png';
// import { AuthContext } from '../../context/auth-context';

const Footer = props => {

    const year = new Date().getFullYear();
    // const auth = useContext(AuthContext)

    return (
        <div className={classes.footer}>
            <div className={classes.footer_copyright}>
                &copy;2018-{year} Invest by Followone. All Rights Reserved. 
                {/* <strong>Version Beta 1.0.0</strong> */}
            </div>

            <div className={classes.footer_nav}>
                {/* <div className={classes.footer_nav_menu}>
                    <a href="https://followone.com/" target="_blank" rel="noopener noreferrer"><img src={followoneImg} alt="Followone" /></a>
                    <a href="https://apps.apple.com/us/app/followone/id1439219757" target="_blank" rel="noopener noreferrer"><img src={appleImg} alt="Followone iOS App" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.followone.app&hl=en" target="_blank" rel="noopener noreferrer"><img src={playstoreImg} alt="Followone Android App" /></a>
                </div> */}

                {/* <div className={classes.footer_nav_menu}>
                    <a href="https://followone.com/about" target="_blank" rel="noopener noreferrer">About</a>
                    <a href="https://followone.com/meet-the-team" target="_blank" rel="noopener noreferrer">The Team</a>
                    <a href="https://followone.com/press" target="_blank" rel="noopener noreferrer">Press</a>
                    <a href="https://followone.com/contact" target="_blank" rel="noopener noreferrer">Contact</a>
                    <a href="https://followone.com" target="_blank" rel="noopener noreferrer">Followone.com</a>
                </div> */}

                <div className={classes.footer_nav_menu}>
                    <button onClick={() => props.openModal('privacy')}>Privacy Policy</button>
                    {/* <button onClick={() => props.openModal('terms')}>Terms of Service</button> */}
                    {/* {auth.isLoggedIn && <button onClick={() => props.openModal('ppm')}>PPM</button>} */}
                    <button onClick={() => props.openModal('copyright')}>Copyright</button>
                </div>
            </div>
        </div>
    );
}

export default Footer;