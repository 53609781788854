import React, { useState, useEffect } from 'react';
import classes from './UpcomingUpdates.module.scss';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import axios from 'axios';
import moment from 'moment';

const UpcomingUpdates = props => {

    const [ news, setNews] = useState();
    const { sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchNews = async () => {
            const pressData = new FormData();

            pressData.append('start', 0);
            pressData.append('count', 100);
            pressData.append('filter', '{}')

            let result = await axios({ url: `https://api.followone.com/api/press/items`, method: 'post', data: pressData });

            let data = result.data;

            if (!data.error) {
                setNews(data.items);
            }
        }

        fetchNews();
    },[sendRequest])

    if (!props.active) {
        return null;
    }

    return (
        <div className={classes.upcomingUpdates}>
            <h4>Press Releases</h4>

            {news.map((v, i) => {

                setTimeout(() => {
                    let myDiv = document.getElementById("pressContentDiv" + i);
                    if (myDiv) myDiv.innerHTML = v.pressContent;
                }, 200);

                return (
                    <div className={classes.press}>

                        <img className={classes.prImage} src={v.featuredImage} alt="Followone Press Release" />

                        <div className={classes.prContent}>
                            <h4>{v.title}</h4>
                            <p className={classes.prMeta}>{v.location} - {moment(v.creationDate).format('LL')}</p>

                            <div id={`pressContentDiv${i}`}>
                            </div>

                            <div className={classes.prContact}>
                                Media Contact<br />
                                <span>Company Name:</span> {v.companyName}<br />
                                <span>Contact Person:</span> {v.contactPerson}<br />
                                <span>Email:</span> {v.contactEmail}<br />
                                <span>Website:</span> {v.website}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default UpcomingUpdates; 