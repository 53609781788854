import React, { useState, useContext, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

import classes from './Home.module.scss';
import { AuthContext } from '../../shared/context/auth-context';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Modal from '../../shared/components/UIElements/Modal';
import LogInModal from '../../shared/components/Modals/LogInModal';
import ForgotPassword from '../../shared/components/Modals/ForgotPassword';
import ResetPassword from '../../shared/components/Modals/ResetPassword';

// import HomeText from './Components/HomeText';
import HomeWhy from './Components/HomeWhy';
import HomeHeader from './Components/HomeHeader';
import HomeTech from './Components/HomeTech';
import HomePlatform from './Components/HomePlatform';
// import HomePlans from './Components/HomePlans';
import HomeAdvertise from './Components/HomeAdvertise';
import HomeStartups from './Components/HomeStartups';
import HomeVerticals from './Components/HomeVerticals';

const Home = props => {

    const auth = useContext(AuthContext);
    const params = useParams();

    const [signInModal, setSignInModal] = useState(false);
    const [forgotPassModal, setForgotPassModal] = useState(false);
    const [resetPassModal, setResetPassModal] = useState(false);

    const token = params.token;

    useEffect(() => {
        if (token) {
            setResetPassModal(true);
        }
    }, [token])

    const openSignInModalHandler = () => {
        setSignInModal(true);
        setForgotPassModal(false);
        setResetPassModal(false);
    }

    const closeSignInModalHandler = () => {
        setSignInModal(false);
    }

    const openForgotPassModalHanlder = () => {
        setSignInModal(false);
        setForgotPassModal(true);
    }

    const closeForgotPassModalHanlder = () => {
        setForgotPassModal(false);
    }

    const closeResetPassModalHandler = () => {
        setResetPassModal(false);
    }

    return (
        <div className={classes.Home}>
            <HomeHeader
                isAuth={auth.isLoggedIn}
                openSignInModalHandler={openSignInModalHandler}
            />

            

            <div className={classes.HomeContent}>
                {/* <HomeText /> */}

                <a id="verticals"></a>
                <h4 className={classes.title}>Meet the Followone Platform</h4>
                
                <div className={classes.inPageNav}>
                    <HashLink smooth className={classes.active} to="/#verticals">Verticals</HashLink>
                    <HashLink smooth to="/#benefits">Benefits</HashLink>
                    <HashLink smooth to="/#tech">Tech</HashLink>
                    <HashLink smooth to="/#platform">Platform</HashLink>
                    <HashLink smooth to="/#advertising">Advertising</HashLink>
                    <HashLink smooth to="/#startups">Start-Ups</HashLink>
                </div>
                <HomeVerticals />
                
                <a id="benefits"></a>
                <div className={classes.inPageNav}>
                    <HashLink smooth to="/#verticals">Verticals</HashLink>
                    <HashLink smooth className={classes.active} to="/#benefits">Benefits</HashLink>
                    <HashLink smooth to="/#tech">Tech</HashLink>
                    <HashLink smooth to="/#platform">Platform</HashLink>
                    <HashLink smooth to="/#advertising">Advertising</HashLink>
                    <HashLink smooth to="/#startups">Start-Ups</HashLink>
                </div>
                <HomeWhy />

                <a id="tech"></a>
                <div className={classes.inPageNav}>
                    <HashLink smooth to="/#verticals">Verticals</HashLink>
                    <HashLink smooth to="/#benefits">Benefits</HashLink>
                    <HashLink smooth className={classes.active} to="/#tech">Tech</HashLink>
                    <HashLink smooth to="/#platform">Platform</HashLink>
                    <HashLink smooth to="/#advertising">Advertising</HashLink>
                    <HashLink smooth to="/#startups">Start-Ups</HashLink>
                </div>
                <HomeTech />

                <a id="platform"></a>
                <div className={classes.inPageNav}>
                    <HashLink smooth to="/#verticals">Verticals</HashLink>
                    <HashLink smooth to="/#benefits">Benefits</HashLink>
                    <HashLink smooth to="/#tech">Tech</HashLink>
                    <HashLink smooth className={classes.active} to="/#platform">Platform</HashLink>
                    <HashLink smooth to="/#advertising">Advertising</HashLink>
                    <HashLink smooth to="/#startups">Start-Ups</HashLink>
                </div>
                <HomePlatform />
                
                {/* Plans - Followone Plans */}
                {/* <HomePlans /> */}

                <a id="advertising"></a>
                <div className={classes.inPageNav}>
                    <HashLink smooth to="/#verticals">Verticals</HashLink>
                    <HashLink smooth to="/#benefits">Benefits</HashLink>
                    <HashLink smooth to="/#tech">Tech</HashLink>
                    <HashLink smooth to="/#platform">Platform</HashLink>
                    <HashLink smooth className={classes.active} to="/#advertising">Advertising</HashLink>
                    <HashLink smooth to="/#startups">Start-Ups</HashLink>
                </div>
                <HomeAdvertise />

                <a id="startups"></a>   
                <div className={classes.inPageNav}>
                    <HashLink smooth to="/#verticals">Verticals</HashLink>
                    <HashLink smooth to="/#benefits">Benefits</HashLink>
                    <HashLink smooth to="/#tech">Tech</HashLink>
                    <HashLink smooth to="/#platform">Platform</HashLink>
                    <HashLink smooth to="/#advertising">Advertising</HashLink>
                    <HashLink smooth className={classes.active} to="/#startups">Start-Ups</HashLink>
                </div>
                <HomeStartups />
            </div>
            
            


            <Modal
                show={signInModal}
                onCancel={closeSignInModalHandler}
                header="Sign In"
            >
                <LogInModal
                    openForgotPass={openForgotPassModalHanlder}
                />
            </Modal>


            <Modal
                show={forgotPassModal}
                onCancel={closeForgotPassModalHanlder}
                header="Forgot Password"
            >
                <ForgotPassword
                    openSignInModal={openSignInModalHandler}
                />
            </Modal>

            <Modal
                show={resetPassModal}
                onCancel={closeResetPassModalHandler}
                header="Reset Password"
            >
                <ResetPassword token={params.token} openSignInModal={openSignInModalHandler} />
            </Modal>
        </div>
    );
}

export default Home;