import React, { useState } from 'react';
import './AdminSettings.scss';
import GenerateInvitationCode from './AdminSettings/GenerateInvitationCode';
import UpdateMarketCapitalization from './AdminSettings/UpdateMarketCapitalization';
import UpdateOfferToPurchaseStock from './AdminSettings/UpdateOfferToPurchaseStock';
import SetPrivateShareSellingRules from './AdminSettings/SetPrivateShareSellingRules';

const AdminSettings = () => {

    const [toggleBox, setToggleBox] = useState({
        invitationBox: false,
        mktgCapBox: false,
        stockOfferBox: true,
        setRullesBox: false
    });

    const openBox = (box1, box2, box3, box4) => {
        setToggleBox({ [box1]: true, [box2]: false, [box3]: false, [box4]: false })
    }

    const boxClasses = (type) => {
        return `profile-settings-box profile-settings-box-${type ? 'open' : 'closed'}`
    }

    const buttonClasses = (type) => {
        return `create-account-content-box-label ${type ? 'create-account-content-box-label-active' : null}`
    }

    const arrrowDir = (type) => {
        return `fal fa-chevron-${type ? 'up' : 'down'}`
    }


    return (
        <div className="profile-settings">
            <h4>Settings</h4>

            <div className="create-account-content-box">
                <button className={buttonClasses(toggleBox.invitationBox)} onClick={() => openBox('invitationBox', 'mktgCapBox', 'stockOfferBox', 'setRullesBox')}>
                    <i className={arrrowDir(toggleBox.invitationBox)}></i>Generate Invitation Code
                </button>

                <div className={boxClasses(toggleBox.invitationBox)}>
                    <GenerateInvitationCode />
                </div>

                <button className={buttonClasses(toggleBox.mktgCapBox)} onClick={() => openBox('mktgCapBox', 'invitationBox', 'stockOfferBox', 'setRullesBox')}>
                    <i className={arrrowDir(toggleBox.mktgCapBox)}></i> Update Authorized Common Shares
                </button>

                <div className={boxClasses(toggleBox.mktgCapBox)}>
                    <UpdateMarketCapitalization />
                </div>

                <button className={buttonClasses(toggleBox.stockOfferBox)} onClick={() => openBox('stockOfferBox', 'invitationBox', 'mktgCapBox', 'setRullesBox')}>
                    <i className={arrrowDir(toggleBox.stockOfferBox)}></i> Update Offer to Purchase Stock
                </button>

                <div className={boxClasses(toggleBox.stockOfferBox)}>
                    <UpdateOfferToPurchaseStock />
                </div>

                <button className={buttonClasses(toggleBox.setRullesBox)} onClick={() => openBox('setRullesBox', 'invitationBox', 'mktgCapBox', 'stockOfferBox')}>
                    <i className={arrrowDir(toggleBox.setRullesBox)}></i> Other Settings
                </button>

                <div className={boxClasses(toggleBox.setRullesBox)}>
                    <SetPrivateShareSellingRules />
                </div>
            </div>
        </div>
    );
}

export default AdminSettings;