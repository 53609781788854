import React, { useState, useEffect } from 'react';
import Button from '../../../shared/components/UIElements/Button';
import classes from './AdminTransactions.module.scss';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useHistory } from 'react-router';
import moment from 'moment';
import numeral from 'numeral';
import Input from '../../../shared/components/UIElements/Input';
import { useForm } from '../../../shared/hooks/form-hook';

const AdminTransactions = () => {


    const [transactions, setTransactions] = useState();
    const [overview, setOverview] = useState(true);
    const [app, setApp] = useState();
    const [usersLength, setusersLength] = useState();
    const { sendRequest } = useHttpClient();
    const history = useHistory();

    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('');

    const [formState, inputHandler] = useForm({
        filter: {
            value: '',
            isValid: false
        },
        sort: {
            value: '',
            isValid: false
        },
    }, false);
 
    useEffect(() => {

        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setApp(responseData.data.data);
            } catch (err) {
                console.log(err);
            }

        }
        fetchApp();

        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users')
                setusersLength(responseData.results);
            } catch (err) {
                console.log(err);
            }

        }
        fetchUsers();
    }, [sendRequest])

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + `/transactions?${filter}&${sort}`)
                setTransactions(responseData.data.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchTransactions();
    }, [sendRequest, filter, sort])

    const toggleView = () => {
        setOverview(!overview);
    }

    const openUserTransactions = (userId) => {
        history.push(`/equity-investments/${userId}`)
    }

    const openUserRevenueTransactions = (userId) => {
        history.push(`/revenue-investments/${userId}`)
    }

    const openEditInvestment = (invId) => {
        history.push(`/admin/edit-investment/${invId}`)
    }

    const onSubmitFilter = (e) => {
        e.preventDefault();
        if (formState.inputs.filter.value) {
            setFilter(formState.inputs.filter.value)
        }

        if (formState.inputs.sort.value) {
            setSort(formState.inputs.sort.value)
        }
        
    }

    const resetFilterHandler = () => {
        formState.inputs.filter.value = ''
        formState.inputs.sort.value = ''
        setFilter('')
        window.location.reload()
        
    }

    if (!app || !transactions || !usersLength) {
        return null;
    }

    return (
        <div className={classes.adminContentTransactions}>

            <div className={classes.outstandingTotalShare}>
                <p>Outstanding Total Share: <span>{numeral(app[0].outstandingCommonShares).format('0,0')}</span></p>
                <p>Total Investors: <span>{numeral(usersLength - 3).format('0,0')}</span></p>
                <p>Total Transactions: <span>{numeral(transactions.length).format('0,0')}</span></p>
            </div>

            <div className={classes.helpNav}>
                <button onClick={toggleView} className="button button--default button--color"> {overview ? "Detailed View" : " List View"} </button>
                
                <form onSubmit={onSubmitFilter}>
                    <Input
                        id="filter"
                        element="select"
                        label="Filter By:"
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                    >
                        <option value="">Choose one...</option>
                        <option value="---------------">----- Rounds -----</option>
                        <option value="round=series-a">Round A</option>
                        <option value="round=series-b">Round B</option>
                        <option value="round=series-c">Round C</option>
                        <option value="round=series-d">Round D</option>
                        <option value="---------------">----- Status -----</option>
                        <option value="transactionStatus=pending">Pending</option>
                        <option value="transactionStatus=in-progress">In Progress</option>
                        <option value="transactionStatus=completed">Completed</option>
                        <option value="---------------">----- Type -----</option>
                        <option value="typeOfTransaction=bonus">Bonus</option>
                        <option value="typeOfTransaction=investment">Investment</option>
                        <option value="typeOfInvestment=equity">Equity</option>
                        <option value="typeOfInvestment=revenue">Revenue</option>
                        <option value="---------------">----- Payment -----</option>
                        <option value="isPaid=true">Paid</option>
                        <option value="isPaid=false">No Payment</option>
                    </Input>

                    <Input
                        id="sort"
                        element="select"
                        label="Sort By:"
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                    >
                        <option value="">Choose one...</option>
                        <option value="sort=transactionDate">Date</option>
                        <option value="sort=noOfShares"># Shares - Asc</option>
                        <option value="sort=-noOfShares"># Shares - Desc</option>
                        <option value="sort=transactionPrice">Payment - Asc</option>
                        <option value="sort=-transactionPrice">Payment - Desc</option>
                        <option value="sort=round">Round</option>
                    </Input>
                    <button type="submit" className="button button--default button--color"> Apply </button>
                    <button onClick={resetFilterHandler} type="button" className="button button--default"> Reset </button>
                </form>
            </div>
            

            {overview &&
                <table className={classes.transactionContainerList}>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Contract/Transfer ID</td>
                            <td>Buyer</td>
                            <td>Transaction Type</td>
                            <td>Round</td>
                            <td># Shares</td>
                            <td>Price / Share</td>
                            <td>Payment Amount</td>
                            <td>Status</td>
                            <td>Tools</td>
                        </tr>
                    </thead>
                                    
                    <tbody>
                        {transactions.map(t => {
                                return (
                                    <tr key={t._id}>
                                        <td>{moment(t.transactionDate).utc().format('MM/DD/YYYY')}</td>
                                        <td>{t.contractId ? t.contractId : t.transferId}</td>
                                        <td>{t.subscriptionAgreement.buyerName}</td>
                                        <td className="title-case">{t.typeOfTransaction}</td>
                                        <td className="title-case">{t.round.replace('-', ' ')}</td>
                                        <td className={ classes.rightAl }>{numeral(t.noOfShares).format('0,0')}</td>
                                        <td className={ classes.rightAl }>${numeral(t.pricePerShare).format('0,0.00')}</td>
                                        <td className={ classes.rightAl }>${numeral(t.transactionPrice).format('0,0.00')}</td>
                                        <td className="title-case">{t.transactionStatus}</td>
                                        <td className={ classes.rightAl }>
                                            { t.typeOfInvestment === 'revenue' && <Button size="icon" onClick={() => openUserRevenueTransactions(t.userBuyer)}><i className="fal fa-dollar-sign"></i></Button> }
                                            <Button size="icon" onClick={() => openUserTransactions(t.userBuyer)}><i className="fal fa-user-tie"></i></Button>
                                            <Button size="icon" onClick={() => openEditInvestment(t._id)}><i className="fal fa-edit"></i></Button>
                                        </td>
                                    </tr> 
                                )
                            }   
                        )}
                    </tbody>
                </table>
            }


            {!overview &&
                <div className={classes.transactionsContainer}>
                    {transactions.map(t => {
                        return (
                            <div key={t._id} className={classes.transactionsContainerBox}>
                                <p>
                                    <span>Contract/Transfer ID</span>
                                    <span>{t.contractId ? t.contractId : t.transferId}</span>
                                </p>
                                <p>
                                    <span>Transaction Date</span>
                                    <span>{moment(t.transactionDate).utc().format('MM/DD/YYYY')}</span>
                                </p>
                                <p>
                                    <span>Transaction Type</span>
                                    <span className="title-case">{t.typeOfTransaction}</span>
                                </p>
                                <p>
                                    <span>Seller</span>
                                    <span>{t.subscriptionAgreement.checkTo}</span>
                                </p>
                                <p>
                                    <span>Buyer</span>
                                    <span>{t.subscriptionAgreement.buyerName}</span>
                                </p>
                                <p>
                                    <span>Investment Type</span>
                                    <span className="title-case">{t.typeOfInvestment}</span>
                                </p>
                                <p>
                                    <span>Shares Type</span>
                                    <span className="title-case">{t.typeOfShare}</span>
                                </p>
                                <p>
                                    <span>Round</span>
                                    <span className="title-case">{t.round.replace('-', ' ')}</span>
                                </p>
                                <p>
                                    <span># Shares</span>
                                    <span>{numeral(t.noOfShares).format('0,0')}</span>
                                </p>
                                <p>
                                    <span>Price / Share</span>
                                    <span>${numeral(t.pricePerShare).format('0,0.00')}</span>
                                </p>
                                <p>
                                    <span>Transaction Amount</span>
                                    <span>${numeral(t.transactionPrice).format('0,0.00')}</span>
                                </p>
                                <p>
                                    <span>Payment</span>
                                    <span className="title-case">{t.paymentType}</span>
                                </p>
                                <p>
                                    <span>Eligibility Date</span>
                                    <span>{moment(t.saleEligibilityDate).utc().format('MM/DD/YYYY')}</span>
                                </p>
                                {t.typeOfInvestment === 'revenue' ? (
                                    <>
                                        <p>
                                            <span>Rev. Contract End Date</span>
                                            <span>{moment(t.revContractEndDate).utc().format('MM/DD/YYYY')}</span>
                                        </p>
                                        <p>
                                            <span>Rev. Contract Length</span>
                                            <span>{t.revContractLength} Years</span>
                                        </p>
                                        <p>
                                            <span>Rev. Revenue/Month</span>
                                            <span>${numeral(t.revContractPerMonth).format('0,0.00')}</span>
                                        </p>
                                    </>)
                                    : null}
                                <p>
                                    <span>Status</span>
                                    <span className="title-case">{t.transactionStatus}</span>
                                </p>
                                <p>
                                    <span>Payment Status</span>
                                    <span>{t.isPaid ? "Paid" : "Not Paid"}</span>
                                </p>
                                <p>
                                    <span>Sub. Ag.</span>
                                    <span><button><i className="fal fa-file-contract"></i></button></span>
                                </p>
                            </div>

                        )
                    }
                    )}
                </div>
            }
        </div >
    );
}

export default AdminTransactions;