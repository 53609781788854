import React from 'react';

const PrivacyPolicy = props => {
    return (
        <div className="subscription-agreement">

            <div className="subscription-agreement-document">


                <h4>Followne Inc.</h4>
                <h3>PRIVACY POLICY</h3>

                <p>Thank you for using our site! This policy is to help you understand what information we collect, and how we use it. If you have any questions, please contact us at support@followone.com.</p>

                <p>WHAT INFORMATION DO WE COLLECT?</p>
                <p>We collect information in two ways:</p>

                <p>1. When you give us your information, or your permission to obtain it.</p>

                <p>When you sign up for, or use our products, your information is voluntarily given to us. This can include your name, profile photo, comments, likes, email address, and any other information you provide us. If you’re using our site on your mobile device, you can also choose to provide us with your location data. You also may give us permission to access your information through other services. For example, you may link your Facebook or Twitter account, which allows us to obtain information from those accounts (e.g., your friends or contacts). The information we obtain often depends on your settings, or their privacy policies.</p>

                <p>2. We receive technical information when you use our products, or use websites or apps that have features from this site.</p>

                <p>Whenever you use a website, mobile application, or other Internet service, certain information almost always gets created and recorded automatically. The same is true when using our products. Here are some examples of the types of information we collect:</p>

                <p>a. Log Data: When you use this site, go to a webpage, or use an app that has features (like our ‘Share’ button), our servers automatically record information (‘Log Data’). This is information that your browser sends whenever you visit a website, or mobile app when you’re using it. This log data may include your Internet Protocol address, address of the web pages you visited with features from this site, browser type, settings, date and time of your request, how you used this website, and cookie data.</p>

                <p>b. Cookie data: Depending on how our products are accessed, we may use “cookies” (a small text file sent by your computer each time you visit our website, unique to your account or your browser), or similar technologies to record log data. When using cookies, we may use ‘session’ cookies (that last until you close your browser) or ‘persistent’ cookies (that last until you or your browser delete them). For example, we may use cookies to store your language preferences, or other settings, so you don‘t have to set them up every time you visit this site. Some of the cookies we use are associated with your account (including personal information about you, such as the email address you gave us), and other cookies are not.</p>

                <p>c. Device Information: In addition to log data, we may also collect information about the device that is using our site. This may include information about what type of device it is, what operating system it’s using, device settings, unique device identifiers, and crash data. Whether we collect some, or all of this information, depends on what type of device is being used and its settings. Different types of information are available depending upon whether a Mac, PC, iPhone, or Android device is being utilized. To learn more about what information your device makes available to us, check the policies of your device manufacturer or software provider.</p>

                <p>HOW DO WE USE THE INFORMATION WE COLLECT?</p>
                <p>We use the information we collect to provide our products to you. This makes them better, helps us develop new products, and protect our users. For example, we may log how often people use two different versions of a product, which can help us understand which version is better.</p>

                <p>We also use the information we collect to offer you customized content, including:</p>

                <p>a. Suggesting content you might like. For example, if you’ve indicated that you’re interested in cooking, or visited recipe websites that have features from this site, we may suggest food-related content, or people that we think you might like.</p>

                <p>b. Showing you ads you might be interested in.</p>

                <p>We also use the information we collect to:</p>

                <p>a. Send you updates (such as when certain activity, like shares or comments), newsletters, marketing materials, and other information that may be of interest to you. Depending on your email notification settings, we may send you weekly updates that include posts you may like. You can decide to stop getting these updates by updating your account settings (or through other settings we may provide).</p>

                <p>b. Help your friends and contacts find you on this site. If you sign up using a Facebook account, we may help your Facebook friends find your account on this site when they first sign up. Or, we may allow people to search for your account using your email address.</p>

                <p>c. Respond to questions or comments.</p>

                <p>The information we collect may be “personally identifiable” (meaning it can be used to specifically identify you as a unique person) or “non-personally identifiable” (meaning it can’t be used to specifically identify you).</p>

                <p>HOW DO WE SHARE THE INFORMATION WE COLLECT?</p>
                <p>When you post public updates, anyone can view them. You may also provide us with profile page information that anyone can view. The other limited instances where we may share your personal information include:</p>

                <p>a. When we have your consent. This includes sharing information with other services (like Facebook or Twitter) when you’ve chosen to link to your account to those services or publish your activity to them. For example, you can choose to publish your posts to Facebook or Twitter.</p>

                <p>b. If we believe that disclosure is necessary to comply with a law, regulation or legal request; to protect the safety, rights, or property of the public, any person; or to detect, prevent, or otherwise address fraud, security, or technical issues.</p>

                <p>c. We may engage in a merger, acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Privacy Policy.</p>

                <p>We may also share aggregated or non-personally identifiable information with our partners or others.</p>

                <p>OUR POLICY ON CHILDREN’S INFORMATION</p>
                <p>Children 13 or under are not permitted to use our website. If you learn that your child has provided us with personal information without your consent, please contact us at support@followone.com</p>

                <p>HOW DO WE MAKE CHANGES TO THIS POLICY?</p>
                <p>This Privacy Policy may change, any updates to this Privacy Policy will be posted on this page. If you continue to use this website after those changes are made, you are agreeing to the revised policy. If the changes are significant, we may provide further notice or obtain your consent as required by law.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;