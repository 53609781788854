import React from 'react';
import styles from './Documents.module.scss';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';

const Documents = props => {
    return (
        <>
            <LoggedInHeader />

            <div className={styles.page}>
                <h4>Investments Documents</h4>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Title</td>
                            <td>Type</td>
                            <td>Files</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>01/17/22</td>
                            <td>Followone Pitch-Deck</td>
                            <td>Presentation</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            
        </>
    )
}

export default Documents;