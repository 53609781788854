import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import { useHistory } from 'react-router-dom';
import "./PendingInvestments.scss";
import moment from 'moment';
import numeral from 'numeral';
import Button from '../../../shared/components/UIElements/Button';

const PendingInvestments = props => {

    const [investments, setInvestments] = useState();
    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions')
                const pendingPayTrans = responseData.data.data.filter(trans => trans.isPaid === false);
                setInvestments(pendingPayTrans);
            } catch (err) {
                console.log(err);
            }
        }
        fetchTransactions();
    }, [sendRequest]);

    const approveTransactionHandler = async (id) => {
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/transactions/${id}`, 'PATCH', JSON.stringify({
                isPaid: true,
                paymentPending: false,
                transactionStatus: "completed"
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })

            history.push('/admin/transactions');
        } catch (err) {
            console.log(err);
        }

    }

    if (!investments) {
        return null;
    }

    return (
        <div className="admin-add-investment">
            <h4>Pending Investments</h4>

            {investments.length === 0 && (<p>No pending investments available.</p>)}

            <div className="pending-investments">
                <table>
                    {investments.length !== 0 && (
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Date</td>
                                <td>Buyer</td>
                                <td>Shares</td>
                                <td>Amount</td>
                                <td>Payment</td>
                                <td>Tools</td>
                            </tr>
                        </thead>
                    )}

                    <tbody>
                        {investments.map(i => {
                            return (
                                <tr key={i._id}>
                                    <td>{i.contractId}</td>
                                    <td>{moment(i.transactionDate).utc().format('MM/DD/YYYY')}</td>
                                    <td>{i.subscriptionAgreement.buyerName}</td>
                                    <td style={{ textAlign: "right" }}>{numeral(i.noOfShares).format('0,0')}</td>
                                    <td style={{ textAlign: "right" }}>${numeral(i.transactionPrice).format('0,0.00')}</td>
                                    <td className="title-case">{i.paymentType}</td>
                                    <td>
                                        <Button
                                            size="icon"
                                            onClick={(id) => approveTransactionHandler(i._id)}
                                        >
                                            <i className="fal fa-thumbs-up"></i>
                                        </Button>
                                        <Button size="icon" disabled><i className="fal fa-file-contract"></i></Button>
                                        <Button size="icon" disabled><i className="fal fa-edit"></i></Button>
                                        <Button color="red" disabled size="icon"><i className="fal fa-trash-alt"></i></Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PendingInvestments;