import React from 'react';
import classes from './HomeWhy.module.scss';


const HomeWhy = props => {
    return (
        <>
            <h2>Followone.com Benefits</h2>

            <div className={classes.dealerBenefits}>
                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-car" style={{color: '#bfbfbf'}}></i>
                    <h4>SHOWCASE INVENTORY</h4>
                    <p>On Followone Auto we manange dealer’s inventory as part of the dealer membership, which can save the dealer about $240k / year.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-search" style={{color: '#74934b'}}></i>
                    <h4>INCREASE FINDABILITY</h4>
                    <p>Our listings show up organically on Google, Bing and Yahoo search.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-link" style={{color: '#397dc0'}}></i>
                    <h4>DRIVING TRAFFIC TO BUSINESSES</h4>
                    <p>We link back to the business website and improve their website's Search Engine Optimization and position on search engines like Google, Bing or Yahoo.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-map-marked-alt" style={{color: '#5dbe7e'}}></i>
                    <h4>GIVE LOCAL EXPOSURE</h4>
                    <p>We focus on bringing local customers to local businesses.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-tags" style={{color: '#ee3130'}}></i>
                    <h4>PROMOTE DEALS & INVENTORY</h4>
                    <p>Our platforms offer unique advertising methods to get listings in front: Featured Ads, Sponsored Ads and Guaranteed Pay Per View Ads.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <img src="https://followone.com/static/media/foaccredited.544c8b54.svg" alt="This business is accredited by Followone" />
                    <h4>BOOST CREDIBILITY</h4>
                    <p>Followone Accredited logo boosts a businesses credibility on our platforms and on the market.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-id-card" style={{color: '#444444'}}></i>
                    <h4>ONE LOGIN</h4>
                    <p>Access to all websites with one login, same user ID and Password.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-photo-video" style={{color: '#66bdd1'}}></i>
                    <h4>CONNECT WITH CLIENTS</h4>
                    <p>Our platform allows users to customize their profile page with products, services, jobs, photos, videos, blogs & events and more.</p>
                </div>

                <div className={classes.dealerBenefitsItem}>
                    <i className="fal fa-users" style={{color: '#29ad7e'}}></i>
                    <h4>BUILD RELATIONSHIPS</h4>
                    <p>Businesses can easily turn customers into followers. Our chat system allowes them to add a personal touch and answer customer's questions on the fly.</p>
                </div>
            </div>
        </>
    )
}

export default HomeWhy;