import React, { useEffect, useState } from 'react';
import Input from '../UIElements/Input';
import Button from '../UIElements/Button';
import { VALIDATOR_EMAIL } from '../../util/validators';
import { useForm } from '../../hooks/form-hook';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/Modals/Error';

const ForgotPassword = (props) => {


    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [emailSent, setEmailSent] = useState(false);

    const [formState, inputHandler] = useForm({
        email: {
            value: '',
            isValid: false
        },
    }, false);

    useEffect(() => {
        return () => {
            setEmailSent(false);
        }
    }, [])

    const onResetPassword = async (event) => {
        event.preventDefault();
        try {

            setEmailSent(true);

            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users/forgotPassword', 'POST', JSON.stringify({
                email: formState.inputs.email.value,
            }), {
                'Content-Type': 'application/json'
            });


        } catch (err) {
            console.log(err);
        }
    }

    // console.log(emailSent)

    return (
        <div>
            {error && <ErrorModal error={error} onClear={clearError} />}
            {isLoading && <LoadingSpinner asOverlay />}

            {!emailSent && <p>Write the email you use to log in, and we will send you a link to reset your password.</p>}

            <form onSubmit={onResetPassword}>

                {!emailSent &&
                    <Input
                        id="email"
                        element="input"
                        type="email"
                        label="Email"
                        validators={[VALIDATOR_EMAIL()]}
                        errorText="Please enter a valid email address."
                        onInput={inputHandler}
                    />
                }

                {emailSent && <p>Email sent succesfully. Please check your email and follow the link to reset your password.</p>}

                <div className="button-content-actions">
                    {!emailSent && <Button type="submit" disabled={!formState.isValid}>Send Email</Button>}
                    {!emailSent && <Button color="white" type="button" onClick={props.openSignInModal}>Sign In</Button>}
                </div>
            </form>

        </div>
    );
}

export default ForgotPassword;