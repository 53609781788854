import React, { useContext } from 'react';
import CreateAccountNav from './CreateAccountNav';
import Input from '../../../shared/components/UIElements/Input';
import './Steps.scss';
import Button from '../../../shared/components/UIElements/Button';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import LoggedInHeader from '../../../shared/components/Navigation/LoggedInHeader';
import { AuthContext } from '../../../shared/context/auth-context';
import { COUNTRIES } from '../../../shared/util/countries';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinnder from '../../../shared/components/UIElements/LoadingSpinner';

import { useForm } from '../../../shared/hooks/form-hook';

const Step4 = props => {

    const auth = useContext(AuthContext);
    const history = useHistory();
    const { sendRequest } = useHttpClient();
    const [formState, inputHandler] = useForm({
        businessName: {
            value: '',
            isValid: false
        }, 
        businessEIN: {
            value: '',
            isValid: true
        },
        jobTitle: {
            value: '',
            isValid: false
        },
        businessEmail: {
            value: '',
            isValid: false
        },
        businessPhone: {
            value: '',
            isValid: true
        },
        businessAddress: {
            value: '',
            isValid: false
        },
        businessAddressLine2: {
            value: '',
            isValid: false
        },
        businessCity: {
            value: '',
            isValid: false
        },
        businessState: {
            value: '',
            isValid: false
        },
        businessZip: {
            value: '',
            isValid: false
        },
        businessCountry: {
            value: '',
            isValid: false
        },
    }, false);

    const saveBusinessInfoHandler = async () => {
        const data = {
            businessName: formState.inputs.businessName.value,
            businessEIN: formState.inputs.businessEIN.value,
            jobTitle: formState.inputs.jobTitle.value,
            businessEmail: formState.inputs.businessEmail.value,
            businessPhone: formState.inputs.businessPhone.value,
            businessAddress: formState.inputs.businessAddress.value,
            businessAddressLine2: formState.inputs.businessAddressLine2.value,
            businessCity: formState.inputs.businessCity.value,
            businessState: formState.inputs.businessState.value,
            businessZip: formState.inputs.businessZip.value,
            businessCountry: formState.inputs.businessCountry.value,
            businessInfoCheck: true,
        }

        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify(data), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
        } catch (err) {
            console.log(err);
        }

        history.push('/dashboard');
    }


    // if (!props.active) {
    //     return null;
    // }

    return (

        <div className="create-account">
            <LoggedInHeader />

            <div className="create-account-content">
            <div className="create-account-content-box">
            <CreateAccountNav
                CreatAccountStepTitle="Business Information"
                // CreateAccountStatusClassStep1="status-complete"
                // CreateAccountStatusClassStep2="status-complete"
                // CreateAccountStatusClassStep3="status-complete"
                // CreateAccountStatusClassStep4="status-current"
                // CreateAccountStatusClassStep5="status-incomplete"
            />

            <label className="create-account-content-box-label">Business Information</label>

            <div className="row">
                <Input
                    id="businessName"
                    type="text"
                    element="input"
                    label="Business Name"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessEIN"
                    type="text"
                    element="input"
                    label="Business EIN"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />
            </div>

            <label className="create-account-content-box-label">Business Contact</label>

            <div className="row">
                <Input
                    id="jobTitle"
                    type="text"
                    element="input"
                    label="Job Title"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessEmail"
                    type="email"
                    element="input"
                    label="Email Address"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessPhone"
                    type="text"
                    element="input"
                    label="Phone #"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />
            </div>

            <label className="create-account-content-box-label">Business Address</label>

            <div className="row">
                <Input
                    id="businessAddress"
                    type="text"
                    element="input"
                    label="Business Address Line 1"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessAddressLine2"
                    type="text"
                    element="input"
                    label="Business Address Line 2"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessCity"
                    type="text"
                    element="input"
                    label="City"
                    errorText="Please enter your city."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />
            </div>

            <div className="row">
                <Input
                    id="businessState"
                    type="text"
                    element="input"
                    label="State/Province"
                    errorText="Please enter your state."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessZip"
                    type="text"
                    element="input"
                    label="Zip/Postal Code"
                    errorText="Please enter your Zip/Postal Code."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="businessCountry"
                    element="select"
                    label="Country"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                >
                    {COUNTRIES.map(c => <option key={c} value={c}>{c}</option>)}
                </Input>
            </div>

            {/* <Input 
                id="sameAddressAsHome"
                type="checkbox"
                element="checkbox"
                align="left"
                label="Business address same as home address."
                onInput={inputHandler}
                validators={[]}
                initialValid={true}
            /> */}


            <div className="button-holder-previous">
                {/* <Button color="white" onClick={props.previous}>Previous</Button> */}
            </div>
            <div className="button-holder-next">
                <Button disabled={!formState.isValid} onClick={saveBusinessInfoHandler}>Save</Button>
            </div>
        </div>
            </div>
        </div>

       
    );
}

export default Step4;