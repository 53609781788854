import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../../shared/components/UIElements/Input';
import Button from '../../../../shared/components/UIElements/Button';
// import ErrorModal from '../../../../shared/components/Modals/Error';
// import LoadingSpinner from '../../../../shared/components/UIElements/LoadingSpinner';
import { VALIDATOR_REQUIRE } from '../../../../shared/util/validators';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { useForm } from '../../../../shared/hooks/form-hook';
import { AuthContext } from '../../../../shared/context/auth-context';



const GenerateInvitationCode = props => {


    const [appDetails, setAppDetails] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    // const { isLoading, error, sendRequest, clearError } = useHttpClient();


    const [formState, inputHandler] = useForm({
        invitationCode: {
            value: '',
            isValid: false
        }
    }, false);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setAppDetails(responseData.data.data[0]);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();

    }, [sendRequest])

    const generateInvitationSubmitHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/app/${appDetails._id}`, 'PATCH', JSON.stringify({
                invitationCode: formState.inputs.invitationCode.value,
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }

    if (!appDetails) {
        return null;
    }
   

    return (
        <form onSubmit={generateInvitationSubmitHandler}>
            <div className="row">
                <Input
                    id="invitationCode"
                    type="text"
                    element="input"
                    label="New Invitaiton Code"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValid={true}
                    initialValue={appDetails.invitationCode}
                />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Invitation code was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Invitation Code</Button>
            </div>
        </form >
    )
}

export default GenerateInvitationCode;