import React from 'react';
import Button from '../../shared/components/UIElements/Button';

const ElectronicConsent = props => {
    return (
        <div className="subscription-agreement">

            <div className="subscription-agreement-document"> 


                <h4>Followne Inc.</h4>
                <h3 style={{lineHeight: '1.2', marginBottom: '2rem'}}>CONSENT TO ELECTRONIC SIGNATURES AND RECORDS IN FOLLOWONE TRANSACTION</h3>

                <p>Please read the information below carefully. If you agree to the terms and conditions, please return to the previous screen and check the box to confirm your consent.</p>

                <p><strong>1. Definitions</strong></p>

                <p>"Consent" refers to this Consent to Electronic Signatures and Records in Followone Transaction agreement. " Followone ", “Followone.com”, “Invest by Followone.com” refers to Followone, Inc. and its subsidiaries and affiliates, including but not limited to, Followone Inc. "You" and "your" mean the person giving consent to complete the requested transaction, and receive certain related documentation, electronically. " Followone " means the electronic signature and delivery technology of Followone, Inc.</p>

                <p><strong>2. Scope of Consent</strong></p>

                <p>Please read both Options (A) and (B) immediately below to determine which applies to you in connection with this Consent.</p>

                <ol className="type-a">
                    <li>
                        <p>If you are planning to complete a property and casualty insurance-related transaction with Invest by Followone.com or one of its property and casualty insurance-related subsidiaries or affiliates (collectively, "Followone"), then Followone is providing this Consent to you in order to obtain your agreement and consent to:</p>

                        <ol className="type-i">
                            <li>Use your electronic signature to process your request for the transaction through Followone, rather than obtain your handwritten signature; and</li>
                            <li>Provide to you in electronic format through Followone any records relating to the transaction that are required by law, including but not limited to the insurance application and application materials, disclosures, notices, servicing materials, claims materials, and documents you sign through Followone, instead of sending these records to you in paper format.</li>
                        </ol>
                    </li>

                    <li>
                        <p>If you are planning to complete a financial services product transaction with Followone is providing this Consent to you in order to obtain your agreement and consent to:</p>

                        <ol className="type-i">
                            <li>Use your electronic signature to process your application for the financial services product transaction through Followone, rather than obtain your handwritten signature; and</li>
                            <li>Provide to you in electronic format through Followone any records relating to the transaction that you sign through Followone, unless otherwise prohibited by law, including but not limited to an electronic copy of your completed application, instead of sending these records to you in paper format.</li>
                        </ol>
                    </li>
                </ol>

                <p><strong>3. Hardware, Software and Operating System</strong></p>

                <p>You are responsible for installation, maintenance and operation of your computer, browser and software. Followone is not responsible for errors or failures from any malfunction of your computer, cellphone, browser or software. Followone is also not responsible for computer, device viruses or related problems associated with use of an online system, or any delay or failure in connection with your receipt of email notices. The following are minimum hardware, software and operating system requirements necessary for Option (A) or Option (B), as applicable*. Operating Systems: Windows ® 2000, Windows® XP, Windows Vista®; Mac OS® X Browsers: Final release versions of Internet Explorer® 6.0 or above (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac); SafariTM 3.0 or above (Mac only) PDF Reader: Acrobat® or similar software may be required to view and print PDF files Screen Resolution: 800 x 600 minimum Enabled Security Settings: Allow per session cookies.</p>

                <p>*These minimum requirements are subject to change. If these requirements change, you will be asked to re-accept the terms herein, as revised, including, as applicable, new required hardware and software requirements. In such instance, you may, without any fee to you, withdraw your consent to receive any copies of your record(s) in electronic-only format as described under the terms herein. However, continuing to use electronic services in connection with a requested electronic transaction after receiving notice of the change is reaffirmation of your consent. Pre-release (e.g. beta) versions of operating systems and browsers are not supported.</p>

                <p><strong>4. Maintaining a Current Email Address</strong></p>

                <p>It is your responsibility to provide Followone with a current, valid email address and to promptly update any changes to this information. You can update your email by contacting your Followone representative or by emailing Followone at invest@followone.com.</p>

                <p><strong>5. Requesting Paper Copies</strong></p>

                <p>You may, at any time, request a paper copy of the record(s) provided to you through the Followone system. To do so, simply contact your Followone representative or email Followone at invest@followone.com. At this time there is no fee for paper copies, however, Followone reserves the right to charge a fee in the future.</p>

                <p>Please note that you will have the ability to download and print the record(s) delivered to you electronically through Followone during and immediately after your electronic signature session.</p>

                <p><strong>6. Legal Effect</strong></p>

                <p>If you agree to the terms and conditions herein, you acknowledge and agree that your Consent is provided and/or obtained in connection with a transaction affecting interstate commerce subject to the Electronic Signatures in Global and National Commerce Act and the Uniform Electronic Transactions Act, or a similar electronic transactions law, as adopted by state law. You further agree that:</p>

                <ol className="type-a">
                    <li>Your electronic signature and electronic records have the same meaning and effect as handwritten signatures and paper records, respectively;</li>
                    <li>The electronic record(s) that we make available to you through the Followone system shall have the same meaning and effect as if you were provided a paper record, whether or not you choose to view the record(s), unless you previously withdrew your consent to receive records electronically through the Followone system; and</li>
                    <li>Electronic records are considered received by you at the time you receive an electronic notification alerting you that a record is available electronically to you through the Followone system unless Invest by Followone.com or Followone.com, as applicable, receive notice that the email notification was not delivered to you at the email address you provided.</li>
                </ol>

                <p><strong>7. Termination / Changes</strong></p>

                <p>Invest by Followone.com  and Followone.com each reserve the right, in their sole discretion, to discontinue the provision of records in electronic format, and to terminate or change the terms and conditions under which they provide such records. Invest by Followone.com or Followone.com, as applicable, will provide you with notice of any such termination or change as required by law.</p>

                <p><strong>8. Right to Decline Consent</strong></p>

                <p>You have the right to decline to consent to Option (A) or Option (B), as applicable, by doing one of the following:</p>

                <ol className="type-a">
                    <li>Return to the previous screen and decline to electronically sign a record from within your Followone session by clicking "Decline" or "Print & Sign", as applicable; or</li>
                    <li>Contact the Followone representative through whom you requested this transaction.</li>
                </ol>

                <p>If you decline to consent, you will not be permitted to proceed with Option (A) or Option (B), as applicable. Instead, you may complete your transaction and receive your record(s) in paper format as described below.</p>

                <p><strong>9. Right to Withdraw Consent</strong></p>

                <p>If you execute this Consent, you may subsequently withdraw your consent at any time and at no cost to you. However, you must inform us of your decision by contacting your Followone representative or by emailing Followone at invest@followone.com</p>

                <p>Please note that withdrawing your consent may slow the speed at which certain steps in the transaction at issue can be completed, as well as the speed at which you will receive your record(s), since these processes will have to be completed in paper format.</p>

                <p><strong>10. Completing Your Transaction in Paper Format</strong></p>

                <p>To complete your transaction in paper format, please contact your Followone representative through whom you requested the transaction.</p>

                <p>By returning to the previous screen and checking the box to confirm your consent, you agree to the terms and conditions contained in this Consent and affirm the following:</p>

                <ul className="type-disc">
                    <li>I can access and read this electronic Consent; and</li>
                    <li>I can print this electronic Consent on paper or electronically save or email it to an email address where I can save or print it on paper for future reference and access; and</li>
                    <li>I understand the scope of this Consent as described in Option (A) or Option (B), as applicable; and</li>
                    <li>I have an account with an Internet service provider (or access to such an account) for personal use, and I am able to send email and receive email with hyperlinks to web sites and attached files; and</li>
                    <li>I understand that my electronic signature on this Consent will not replace or alter any electronic signature or record any elections I may have made for other Followone products and services; and</li>
                    <li>If applicable and as permitted by law, I consent on behalf of all applicants in connection with this requested electronic transaction to the use of electronic signatures and electronic records.</li>

                </ul>

            </div>

            {!props.isCreateAccount &&
                <div className="button-content-actions">
                    <Button onClick={props.next}>I Agree with the Electronic Consent</Button>
                    <Button size="link" onClick={props.close}>I Disagree</Button>
                </div>
            }
        </div>
    );
}

export default ElectronicConsent;