import React from 'react';

import classes from './Logo.module.scss';

const Logo = () => (
    <div className={classes.logo}>
        <p className={classes.logo_investments}>invest <span className={classes.logo_investments_dot}></span></p>
        <p className={classes.logo_follow}>
            <span className={classes.logo_follow_smtxt}>by </span>
            Follow<span className={classes.logo_follow_blue}>one</span>
            <span className={classes.logo_follow_smtxt}>.com</span>
        </p>
    </div>
)

export default Logo;