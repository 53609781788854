import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from './Nav';
import { AuthContext } from '../../context/auth-context';

import classes from './Header.module.scss';

import * as uiActions from '../../store/actions';

const Header = props => {

    const auth = useContext(AuthContext);

    const dispatch = useDispatch();
    const onMenuButtonClick = () => dispatch(uiActions.toggleMainNav());
    const toggleMenuHandler = useSelector(state => state.ui.mainNavToggle);

    return (
        <div className={classes.header}>
            {auth.isLoggedIn &&
                <button className={classes.navigation_button} onClick={onMenuButtonClick}>
                    <i className="fal fa-bars"></i>
                </button>}

            <Menu active={toggleMenuHandler} />

        </div>
    );
}

export default Header;