import React from 'react';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import AdminSettings from './Components/AdminSettings';
import AdminNav from './Components/AdminNav';
import AdminTransactions from './Components/AdminTransactions';
import AdminInvestors from './Components/AdminInvestors';
import AdminStatus from './Components/AdminStatus';
import './Admin.scss';
import { withRouter } from 'react-router-dom';
import AddInvestment from './Components/AddInvestment';
import AddInvestor from './Components/AddInvestor';
import PendingInvestments from './Components/PendingInvestments';

const Admin = props => {

    let contentBox;

    if (props.match.params.adminPage === 'transactions' || props.match.params.adminPage === undefined) contentBox = <AdminTransactions />
    if (props.match.params.adminPage === 'investors') contentBox = <AdminInvestors />
    if (props.match.params.adminPage === 'settings') contentBox = <AdminSettings />
    if (props.match.params.adminPage === 'add-investment') contentBox = <AddInvestment />
    if (props.match.params.adminPage === 'pending-investments') contentBox = <PendingInvestments />
    if (props.match.params.adminPage === 'status') contentBox = <AdminStatus />
    if (props.match.params.adminPage === 'add-investor') contentBox = <AddInvestor />

    return (
        <div className="admin">
            <LoggedInHeader />

            <div className="admin-content">

                <h4>Super Admin Settings</h4>

                <AdminNav />

                {contentBox}

            </div>
        </div>
    );
}

export default withRouter(Admin);