import React, { useReducer, useEffect } from 'react';
import './Input.scss';
import { validate } from '../../util/validators';

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            }
        case 'TOUCH':
            return {
                ...state,
                isTouched: true
            }
        default:
            return state;
    }
}

const Input = props => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || '',
        isValid: props.initialValid || false,
        isTouched: false
    })

    const { value, isValid } = inputState;
    const { id, onInput } = props;

    useEffect(() => {
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    const changeHandler = event => {
        if (props.type === "checkbox") {
            dispatch({ type: 'CHANGE', val: event.target.checked, validators: props.validators })
            if (props.actionChange) {
                props.actionChange(event);
            }
        } else if (props.type === "radio") {
            dispatch({ type: 'CHANGE', val: event.target.checked, validators: props.validators })
            if (props.actionChange) {
                props.actionChange(event);
            }
        } else {
            dispatch({ type: 'CHANGE', val: event.target.value, validators: props.validators })
            if (props.actionChange) {
                props.actionChange(event);
            }
        }
    }


    const touchHandler = () => {
        dispatch({
            type: 'TOUCH'
        })
    }

    let element;

    if (props.element === 'input') {

        element = (
            <input
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
                disabled={props.disabled || false}
            />
        )
    }

    if (props.element === 'textarea') {
        element = (
            <textarea
                id={props.id}
                rows={props.rows || 3}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
            />
        )
    }

    if (props.element === 'select') {
        element = (
            <select
                id={props.id}
                value={inputState.value}
                onChange={changeHandler}
                onBlur={touchHandler}
            >
                {props.children}
            </select>
        )
    }

    if (props.element === 'checkbox') {
        return (
            <div className={`form-control form-control--checkbox form-control--checkbox--${props.align || 'center'} ${!inputState.isValid && inputState.isTouched && 'form-control--invalid'}`}>
                <input type={props.type} id={props.id} onBlur={touchHandler} onChange={changeHandler} value={inputState.value} checked={props.checked} /> <label htmlFor={props.id}>{props.label}</label>
                {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
            </div>
        )
    }

    if (props.element === 'radio') {
        return (
            <div className={`form-control form-control--radio form-control--radio--${props.align || 'center'} ${!inputState.isValid && inputState.isTouched && 'form-control--invalid'}`}>
                <input type={props.type} id={props.id} onBlur={touchHandler} name={props.name} onChange={changeHandler} value={props.radioValue} /> <label htmlFor={props.id}>{props.label}</label>
                {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
            </div>
        )
    }

    return (
        <div className={`form-control ${!inputState.isValid && inputState.isTouched && 'form-control--invalid'}`}>
            <label htmlFor={props.id}>{props.label}</label>
            {element}
            {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
        </div>
    )
}

export default Input;