import React from 'react';
// import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './Modal.scss';

import Backdrop from './Backdrop';
import Button from './Button';

const ModalOverlay = props => {
    const content = (
        <div className={`modal ${props.className} modal--${props.size || 'small'}`} style={props.style}>
            <header className={`modal__header ${props.headerClass}`}>
                <h4>{props.header}</h4>
                <Button size="close" onClick={props.onCancel}>X</Button>
            </header>
            <div className={`modal__content ${props.contentClass}`}>
                {props.children}
            </div>
            <footer className={`modal__footer ${props.footerClass}`}>
                {props.footer}
            </footer>
        </div>
    )
    // return ReactDOM.createPortal(content, document.getElementById('modal-hook'))
    return content;
}

const Modal = props => {
    return (
        <>

            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="modal"
            >
                <Backdrop onClick={props.onCancel}>
                    <ModalOverlay {...props} />
                </Backdrop>
            </CSSTransition>

        </>
    )
};

export default Modal;







// const ModalBox = props => {
//     return (
//         <>
//             <div className={classes.modal_background} onClick={props.close}></div>
//             <div className={classes.modal_box}>
//                 <div className={props.ModalClassName}>
//                     <div className={classes.modal_box_header}>
//                         <p>{props.ModalBoxTitle}</p>
//                         <button onClick={props.close}><i className="fal fa-times"></i></button>
//                     </div>

//                     <div className={classes.modal_box_content}>
//                         {props.children}
//                     </div>

//                     <div className={classes.modal_box_footer}>
//                         <Button onClick={props.clickedPrimary}>{props.MainButtonCta}</Button>&nbsp;&nbsp;&nbsp;
//                         {props.SecButtonCta === "none" ? null : <Button size="link" onClick={props.clickedSecondary}>{props.SecButtonCta}</Button>}

//                     </div>
//                 </div>

//             </div>
//         </>
//     );
// }

// export default ModalBox; 