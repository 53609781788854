import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../shared/components/UIElements/Button';
import ImageUpload from '../../../shared/components/UIElements/ImageUpload';

import { useForm } from '../../../shared/hooks/form-hook';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const UpdateProfileImage = props => {

    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    const [successMsg, setSuccessMsg] = useState(false);
    const [userImg, setUserImg] = useState();

    const [formState, inputHandler] = useForm({
        photo: {
            value: null,
            isValid: false
        }
    }, false)

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setUserImg(responseData.data.data.photo);
            } catch (err) {
                console.log(err);
            }
        }

        fetchUser();
    }, [sendRequest, auth.userId])

    const updateProfileImageHandler = async event => {
        event.preventDefault();

        try {
            const formData = new FormData();
            formData.append('photo', formState.inputs.photo.value);

            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', formData, {
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);

        } catch (err) {
            console.log(err);
        }
    }

    if (!userImg) {
        return null;
    }

    return (
        <form onSubmit={updateProfileImageHandler}>
            <div className="row">
                <ImageUpload currentImg={userImg} id="photo" onInput={inputHandler} text="Select Profile Photo" />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Profile photo was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Update Photo</Button>
            </div>
        </form>
    )
}

export default UpdateProfileImage;