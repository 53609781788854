import React, { useEffect, useState } from 'react';
import Input from '../UIElements/Input';
import Button from '../UIElements/Button';
import { useForm } from '../../hooks/form-hook';
import { useHttpClient } from '../../hooks/http-hook';
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EQUAL } from '../../util/validators';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/Modals/Error';

const ResetPassword = (props) => {


    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [resetPassSuccess, setResetPassSuccess] = useState(false);

    const [formState, inputHandler] = useForm({
        password: {
            value: '',
            isValid: false
        },
        passwordConfirm: {
            value: '',
            isValid: false
        },
    }, false);

    useEffect(() => {
        return () => {
            setResetPassSuccess(false);
        }
    }, [])


    const onResetPassword = async (event) => {
        event.preventDefault();

        try {

            setResetPassSuccess(true);

            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users/resetPassword/' + props.token, 'PATCH', JSON.stringify({
                password: formState.inputs.password.value,
                passwordConfirm: formState.inputs.passwordConfirm.value,
            }), {
                'Content-Type': 'application/json'
            });

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>

            {error && <ErrorModal error={error} onClear={clearError} />}
            {isLoading && <LoadingSpinner asOverlay />}

            {!resetPassSuccess &&
                <>
                    <p>Enter a new password that contains at least 8 characters.</p>
                    <form onSubmit={onResetPassword}>
                        <Input
                            id="password"
                            element="input"
                            type="password"
                            label="Password"
                            validators={[VALIDATOR_MINLENGTH(8), VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid password."
                            onInput={inputHandler}
                        />

                        <Input
                            id="passwordConfirm"
                            element="input"
                            type="password"
                            label="Confirm Password"
                            validators={[VALIDATOR_EQUAL(formState.inputs.password.value), VALIDATOR_REQUIRE()]}
                            errorText="Your password does not match."
                            onInput={inputHandler}
                        />

                        <div className="button-content-actions">
                            <Button type="submit" disabled={!formState.isValid}>Update Password</Button>
                            <Button color="white" onClick={() => { }}>Sign In</Button>
                        </div>
                    </form>
                </>
            }

            {resetPassSuccess &&
                <>
                    <p>Password updated succesfully.</p>
                    <div className="button-content-actions">
                        <Button type="button" onClick={props.openSignInModal}>Sign In</Button>
                    </div>
                </>
            }
        </div>
    );
}

export default ResetPassword;