import React, { useContext, useState, useEffect } from 'react';
import VerificationLetter from '../../assets/accredited-status-certification-letter-a.pdf';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import Button from '../../shared/components/UIElements/Button';
import ImageUpload from '../../shared/components/UIElements/ImageUpload';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

import classes from './VerificationProcess.module.scss';

const VerificationProcess = props => {

    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    const [successMsg, setSuccessMsg] = useState(false);
    const [user, setUser] = useState();
    const history = useHistory();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setUser(responseData.data.data);
            } catch (err) {
                console.log(err);
            }
        }

        fetchUser();
    }, [sendRequest, auth.userId])

    const [formState, inputHandler] = useForm({
        uploadId: {
            value: null,
            isValid: false
        },
        uploadAccrediationDoc: {
            value: null,
            isValid: false
        }
    }, false)

    const updateDocumentsHandler = async event => {
        event.preventDefault();

        try {

            const formData = new FormData();
            formData.append('uploadId', formState.inputs.uploadId.value);
            formData.append('uploadAccrediationDoc', formState.inputs.uploadAccrediationDoc.value);

            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', formData, {
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);

            setTimeout(() => {
                history.push("/buy-shares")
            }, 3000)

        } catch (err) {
            console.log(err);
        }
    }

    if (!user) {
        return null;
    }

    return (
        <>
            <LoggedInHeader />
            <div className={classes.verification_process}>
                <div className={classes.verification_process_needs_vf}>
                    <h4>We Need to Verify Your Accreditation <br />3 EASY-STEPS PROCESS</h4>

                    <form onSubmit={updateDocumentsHandler}>

                        <div className={classes.verification_process_box}>
                            <p className={classes.title}>1. Download, fill and sign the Accredited Status Certficiation Letter Template</p>
                            <Button to={VerificationLetter} size="" color="green" target="_blank">Download Certification Letter Template</Button>
                            
                            <div className={classes.divider}></div>

                            <p className={classes.title}>2. Upload the Accredited Status Certification Letter</p>
                            <ImageUpload
                                currentImg={user.uploadAccrediationDoc}
                                id="uploadAccrediationDoc"
                                onInput={inputHandler}
                                text="Update Accreditation Proof"
                                inline
                            />

                            <div className={classes.divider}></div>

                            <p className={classes.title}>3. Upload a copy of your State ID, Driver License ID or Passport</p>
                            <ImageUpload
                                id="uploadId"
                                onInput={inputHandler}
                                text="Update Your ID"
                            />
                        </div>


                        {successMsg &&
                            (
                                < div className="success-message" style={{ marginTop: "2rem" }}>
                                    <p>Documents added succesfuly.</p>
                                    <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                                </div>
                            )
                        }


                        <div className="button-content-actions">
                            <Button type="submit" disabled={!formState.isValid}>Submit Documents</Button>
                            <Button size="link" to="/dashboard">Cancel</Button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    );
}

export default VerificationProcess;