import React from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../UIElements/Logo';

import classes from './LoggedInHeader.module.scss';

const LoggedInHeader = props => {

    return (
        <div className={classes.logged_in_header}>
            <div style={{ cursor: 'pointer' }} onClick={() => props.history.push('/dashboard')}>
                <Logo />
            </div>
        </div>
    );
}

export default withRouter(LoggedInHeader);