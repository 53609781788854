import { useState, useEffect } from 'react';
import './AdminStatus.scss';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import numeral from 'numeral';

const AdminStatus = (props) => {

    const [series0, setSeries0] = useState(0);
    const [seriesA, setSeriesA] = useState(0);
    const [seriesB, setSeriesB] = useState(0);
    const [seriesC, setSeriesC] = useState(0);
    const [seriesD, setSeriesD] = useState(0);

    const [series0Counter, setSeries0Counter] = useState(0);
    const [seriesACounter, setSeriesACounter] = useState(0);
    const [seriesBCounter, setSeriesBCounter] = useState(0);
    const [seriesCCounter, setSeriesCCounter] = useState(0);
    const [seriesDCounter, setSeriesDCounter] = useState(0);

    const [users, setUsers] = useState();

    const { sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchTransactions = async () => {
            const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions');

            console.log('length', responseData.data.data.length);

            let series0AmountRaised = 0;
            let seriesAAmountRaised = 0;
            let seriesBAmountRaised = 0;
            let seriesCAmountRaised = 0;
            let seriesDAmountRaised = 0;

            let series0Counter = 0
            let seriesACounter = 0
            let seriesBCounter = 0
            let seriesCCounter = 0
            let seriesDCounter = 0
        
            responseData.data.data.forEach(t => {
                 if ((t.round === 'founders-investment' || t.round === 'seed-investment' || t.round === 'family-friends' ) && t.isPaid === true) {
                    series0AmountRaised = series0AmountRaised + (t.noOfShares  * t.pricePerShare);
                    series0Counter++
                 }    
            })

            responseData.data.data.forEach(t => {
                if ((t.round === 'series-a' ) && t.isPaid === true) {
                    seriesAAmountRaised = seriesAAmountRaised + (t.noOfShares  * t.pricePerShare);
                    seriesACounter++
                }
            })

            responseData.data.data.forEach(t => {
                if ((t.round === 'series-b' ) && t.isPaid === true) {
                    seriesBAmountRaised = seriesBAmountRaised + (t.noOfShares  * t.pricePerShare);
                    seriesBCounter++
                }
            })

            responseData.data.data.forEach(t => {
                if ((t.round === 'series-c' ) && t.isPaid === true) {
                    seriesCAmountRaised = seriesCAmountRaised + (t.noOfShares  * t.pricePerShare);
                    seriesCCounter++
                }
            })

            responseData.data.data.forEach(t => {

                if (t.round === 'series-d' && t.isPaid === true) {

                    // console.log('PRICE', t.noOfShares  * t.pricePerShare, ' - ' ,t.contractId, t._id)

                    seriesDAmountRaised = seriesDAmountRaised + (t.noOfShares  * t.pricePerShare);
                    seriesDCounter++
                }
            })

            setSeries0(series0AmountRaised);
            setSeriesA(seriesAAmountRaised);
            setSeriesB(seriesBAmountRaised);
            setSeriesC(seriesCAmountRaised);
            setSeriesD(seriesDAmountRaised);

            setSeries0Counter(series0Counter);
            setSeriesACounter(seriesACounter);
            setSeriesBCounter(seriesBCounter);
            setSeriesCCounter(seriesCCounter);
            setSeriesDCounter(seriesDCounter);
        }

        fetchTransactions();

        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users')

                let noShareholders = 0;

                responseData.data.data.forEach(u => {
                    if (u.totalShares > 0 && ( u.role === 'investor' || u.role === "superadmin" || u.role === "director" || u.role === "partner" ) ) {
                        noShareholders++
                    }
                })

                setUsers(noShareholders)

            } catch (err) {
                console.log(err);
            }
        }

        fetchUsers();
    },[sendRequest])

    // console.log(seriesD, seriesDCounter)

    return (
        <div>

            <h4>Investments Status</h4>

            <div> </div>

            <table className="summary-table"> 
                <tbody>
                    <tr>
                        <td>SHAREHOLDERS:</td>
                        <td>{numeral(users).format('0,0')}</td>
                    </tr>
                    <tr>
                        <td>TOTAL TRANSACTIONS:</td>
                        <td>{numeral(series0Counter + seriesACounter + seriesBCounter + seriesCCounter + seriesDCounter).format('0,0')}</td>
                    </tr>
                    <tr>
                        <td>TOTAL INVESTMENTS:</td>
                        <td>${numeral(series0 + seriesA + seriesB + seriesC + seriesD).format('0,0.00')}</td>
                    </tr>
                </tbody>
            </table>

            <div className="rounds-container">

                <div className="round-box">
                    <h4>Seed Investment & Family & Friends</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Transactions</td>
                                <td>{ series0Counter }</td>
                            </tr>
                            <tr>
                                <td>Total Investments</td>
                                <td>${numeral(series0).format('0,0.00')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="round-box">
                    <h4>Series A</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Transactions</td>
                                <td>{ seriesACounter }</td>
                            </tr>
                            <tr>
                                <td>Total Investments</td>
                                <td>${numeral(seriesA).format('0,0.00')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="round-box">
                    <h4>Series B</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Transactions</td>
                                <td>{ seriesBCounter }</td>
                            </tr>
                            <tr>
                                <td>Total Investments</td>
                                <td>${numeral(seriesB).format('0,0.00')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="round-box">
                    <h4>Series C</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Transactions</td>
                                <td>{ seriesCCounter }</td>
                            </tr>
                            <tr>
                                <td>Total Investments</td>
                                <td>${numeral(seriesC).format('0,0.00')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="round-box">
                    <h4>Series D</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Transactions</td>
                                <td>{ seriesDCounter }</td>
                            </tr>
                            <tr>
                                <td>Total Investments</td>
                                <td>${numeral(seriesD).format('0,0.00')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default AdminStatus;