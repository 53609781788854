import React, { useState, useContext, useEffect } from 'react';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import classes from './Transactions.module.scss';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

const Transactions = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [equityInv, setEquityInv] = useState();
    const [revenueInv, setRevenueInv] = useState();

    let userId = auth.userId;

    useEffect(() => {
        if (userId) {
            const fetchRevenue = async () => {
                try {
                    const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}/transactions/revenue`)
                    setRevenueInv(responseData.data);
                } catch (err) {
                    console.log(err);
                }

            }
            fetchRevenue();

            const fetchEquity = async () => {
                if (userId) {
                    try {
                        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}/transactions/equity`)
                        setEquityInv(responseData.data);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            fetchEquity();
        }
    }, [sendRequest, userId])

    if (!userId || !equityInv || !revenueInv) {
        return null;
    }

    return (
        <div className={classes.transactions}>
            <LoggedInHeader />

            <div className={classes.transactionsContainer}>

                <h4 style={{ marginBottom: "2rem" }}>Transactions</h4>

                {equityInv.transactions.length <= 0 && revenueInv.transactions.length <= 0 ? <p>No transactions available.</p> : ''}

                {equityInv.transactions.map(e => {

                    console.log(e)

                    return <div key={e._id} className={classes.roundTransaction}>

                        <div className={classes.roundTransactionItem}>
                            <div className={classes.transactionsContainerBought}>
                                <div><span>Contract ID</span> {e.contractId}</div>
                                <div><span>Date Purchased</span> {moment(e.transactionDate).utc().format('MM-DD-YY')}</div>
                                <div><span>Seller</span> {e.subscriptionAgreement.sellerName}</div>
                                <div><span>Shares</span> {numeral(e.noOfShares).format('0,0')}</div>
                                <div><span>Price/Share</span> {numeral(e.subscriptionAgreement.pricePerShare).format('$0,0.00')}</div>
                                <div><span>Amount</span> {numeral(e.subscriptionAgreement.transactionPrice).format('$0,0.00')}</div>
                                <div><span>Payment</span> {e.paymentType}</div>
                                <div><span>Status</span> {e.transactionStatus}</div>
                                {/* { e.round === "series-d" && <div><Link to={`/subscription-agreement/${e._id}?sa=true`}><i className="fal fa-file-contract"></i></Link></div> } */}
                            </div>

                            <div className={classes.transactionsContainerSell}>
                                <div>No shares available for sale.</div>
                                {/* <div><span>Available Shares:</span> 5,000</div>
                                <div><button className="button button-regular button-small">Sell Shares</button></div> */}
                            </div>
                        </div>

                    </div>
                })}


                {revenueInv.transactions.map(e => {

                    console.log(e)

                    return <div key={e._id} className={classes.roundTransaction}>

                        <div className={classes.roundTransactionItem}>
                            <div className={classes.transactionsContainerBought}>
                                <div><span>Contract ID</span> {e.contractId}</div>
                                <div><span>Date Purchased</span> {moment(e.transactionDate).utc().format('MM-DD-YY')}</div>
                                <div><span>Seller</span> {e.subscriptionAgreement.sellerName}</div>
                                <div><span>Shares</span> {numeral(e.noOfShares).format('0,0')}</div>
                                <div><span>Price/Share</span> {numeral(e.subscriptionAgreement.pricePerShare).format('$0,0.00')}</div>
                                <div><span>Amount</span> {numeral(e.subscriptionAgreement.transactionPrice).format('$0,0.00')}</div>
                                <div><span>Payment</span> {e.paymentType}</div>
                                <div><span>Status</span> {e.transactionStatus}</div>
                                {/* { e.round === "series-d" && <div><Link to={`/subscription-agreement/${e._id}?sa=true`}><i className="fal fa-file-contract"></i></Link></div> } */}
                            </div>

                            <div className={classes.transactionsContainerSell}>
                                <div>No shares available for sale.</div>
                                {/* <div><span>Available Shares:</span> 5,000</div>
                                <div><button className="button button-regular button-small">Sell Shares</button></div> */}
                            </div>
                        </div>

                    </div>
                })}

            </div>
        </div>
    );

}

export default Transactions;




