import React from 'react';
import classes from './HomeVerticals.module.scss';

import AutoImg from '../../../assets/homepage/auto.jpg';
import FoodImg from '../../../assets/homepage/food.jpg';
import JobsImg from '../../../assets/homepage/jobs.jpg';
import RealEstateImg from '../../../assets/homepage/realestate.jpg';
import ServicesImg from '../../../assets/homepage/services.jpg';
import StoreImg from '../../../assets/homepage/store.jpg';
import TravelImg from '../../../assets/homepage/travel.jpg';
// import HealthcareImg from '../../../assets/homepage/healthcare.jpg';

const HomeVerticals = props => {
    return (
        <>
            <h2>Vertically Integrated Businesses</h2>

            <div className={classes.verticals}>

                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>

                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-car-mirrors"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Auto</h3>
                                <p>Auto Dealers from U.S. & Canada</p>
                            </div>
                        </div>

                        <table className={classes.dataTable}>
                            <tbody>
                                <tr><td><i className="fa-light fa-cars"></i></td><td>~6M Auto Listings</td></tr>
                                <tr><td><i className="fa-light fa-car-building"></i></td><td>107k+ Auto Dealers</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <img src={AutoImg} alt="Followone Auto" />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>

                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-sign-hanging"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Real Estate</h3>
                                <p>Pacific Northwest Residential & Commercial Properties</p>
                            </div>
                        </div>

                        <table className={classes.dataTable}>
                            <tbody>
                                <tr><td><i className="fa-light fa-house-building"></i></td><td>$1.9M+ Real Estate Listings</td></tr>
                                <tr><td><i className="fa-light fa-user-tie"></i></td><td>1.2k+ Agencies</td></tr>
                            </tbody>
                        </table>

                        <br />

                        <p>Followone Real Estate Inc. has a real-estate brokerage license in the following states*:</p>

                        <table className={classes.infoTable}>
                            <tbody>
                                <tr><td>STATE</td><td>LICENSE NO.</td></tr>
                                <tr><td>Washington</td><td>20117919</td></tr>
                            </tbody>
                        </table>

                        <p>*Coming soon in all U.S. States.</p>
                    </div>

                    <img src={RealEstateImg} alt="Followone Real Estate"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-bags-shopping"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Store</h3>
                                <p>Followone Outlets on Followone.com</p>
                            </div>
                        </div>

                        <table className={classes.dataTable}>
                            <tbody>
                                <tr><td><i className="fa-light fa-bags-shopping"></i></td><td>4+ Million Products</td></tr>
                                <tr><td><i className="fa-light fa-list-tree"></i></td><td>100+ Categories</td></tr>
                            </tbody>
                        </table>
                    </div>

                    <img src={StoreImg} alt="Followone Store"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-fork-knife"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Foods</h3>
                                <p>Restaurant Menues, Deli Store Items & More</p>
                            </div>
                        </div>
                    </div>

                    <img src={FoodImg} alt="Followone Food"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}> 
                                <i className="fa-thin fa-briefcase"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Jobs</h3>
                                <p>From Intership to C-Level Jobs</p>
                            </div>
                        </div>
                    </div>

                    <img src={JobsImg} alt="Followone Jobs"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-bell-concierge"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Services</h3>
                                <p>Services in 200+ Categories</p>
                            </div>
                        </div>
                    </div>

                    <img src={ServicesImg} alt="Followone Services"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-map-location-dot"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Travel</h3>
                                <p>UNDER DEVELOPMENT</p>
                            </div>
                        </div>
                    </div>

                    <img src={TravelImg} alt="Followone Travel"  />
                </div>



                <div className={classes.vertical}>
                    <div className={classes['vertical-desc']}>
                        <div className={classes.verticalHeader}>
                            <div className={classes.verticalHeaderIcon}>
                                <i className="fa-thin fa-wave-pulse"></i>
                            </div>
                            
                            <div className={classes.verticalHeaderTitle}>
                                <h3>Followone Healthcare</h3>
                                <p>UNDER DEVELOPMENT</p>
                            </div>
                        </div>
                    </div>

                    {/* <img src={HealthcareImg} alt="Followone Heathcare"  /> */}
                </div>
               
            </div>
        </>
    )
}

export default HomeVerticals;