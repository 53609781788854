import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const UpdateAccreditationStatus = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [loggedInUser, setLoggedInUser] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setLoggedInUser(responseData.data.data);
                setIsChecked(responseData.data.data.accreditationStatus);
            } catch (err) {
                console.log(err);
            }
        }

        fetchApp();
    }, [sendRequest, auth.userId])



    const updateAccreditationStatusHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify({
                accreditationStatus: isChecked,
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }

    if (!loggedInUser) {
        return null;
    }

    return (
        <form onSubmit={updateAccreditationStatusHandler}>
            <p style={{ marginBottom: "1rem" }}>
                Rule 501(a) of Regulation D defines an “accredited investor” as any person who comes within any of the following categories, or whom the issuer reasonably believes comes within any of the following categories, at the time of the sale of the securities to that person:
            </p>

            <ul>
                <li>A natural person who had an individual income in excess of $200,000, or joint income with that person’s spouse in excess of $300,000, in each of the two most recent years and reasonably expects to reach the same income level in the current year.</li>
                <li>A natural person whose net worth, either individually or jointly with such person’s spouse, at the time of Subscriber’s purchase, exceeds $1,000,000.</li>
                <li>A bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act, whether acting in its individual or fiduciary capacity.</li>
                <li>A broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of 1934, as amended (the “Exchange Act”).</li>
                <li>An insurance company as defined in section 2(13) of the Exchange Act.</li>
                <li>An investment company registered under the Investment Company Act of 1940 or a business development company as defined in Section 2(a)(48) of that Act.</li>
                <li>A Small Business Investment Company licensed by the U.S. Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958.</li>
                <li>A plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state, or its political subdivisions for the benefit of its employees, if such plan has total assets in excess of $5,000,000.</li>
                <li>An employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974, if the investment decision is made by a plan fiduciary, as defined in Section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons that are accredited investors.</li>
                <li>A private business development company as defined in Section 202(a)(22) of the Investment Advisers Act of 1940.</li>
                <li>An organization described in Section 501(c)(3) of the Internal Revenue Code, or a corporation, business trust or partnership, not formed for the specific purpose of acquiring the Shares, with total assets in excess of $5,000,000.</li>
                <li>A director or executive officer of the Company.</li>
                <li>A trust, with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the Shares, whose purchase is directed by a sophisticated person who has such knowledge and experience in financial and business matters that such person is capable of evaluating the merits and risks of investing in the Company.</li>
                <li>An entity in which all of the equity owners qualify under any of the above subparagraphs.</li>
            </ul>

            <div className="update-accreditation-checkbox">
                <Input
                    id="accreditationStatus"
                    type="checkbox"
                    element="checkbox"
                    label="By checking this box you certify that you are a qualified accredited investor according to at least one condition above."
                    onInput={() => { }}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.accreditationStatus}
                    actionChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isChecked}
                />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Accreditation status was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Now</Button>
            </div>
        </form>
    )
}

export default UpdateAccreditationStatus;