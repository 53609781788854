import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../../shared/components/UIElements/Input';
import Button from '../../../../shared/components/UIElements/Button';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { useForm } from '../../../../shared/hooks/form-hook';
import { AuthContext } from '../../../../shared/context/auth-context';

const UpdateOfferToPurchaseStock = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    const [offers, setOffers] = useState([]);
    const [currentOffer, setCurrentOffer] = useState();
    const [noOffer, setNoOffer] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const [successMsg, setSuccessMsg] = useState(false);
    const [formState, inputHandler] = useForm({
        selectOffer: {
            value: '',
            isValid: false
        },
        series: {
            value: '',
            isValid: false
        },
        round: {
            value: '',
            isValid: false
        },
        max: {
            value: '',
            isValid: false
        },
        min: {
            value: '',
            isValid: false
        },
        priceShare: {
            value: '',
            isValid: false
        },
        totalSharesIssued: {
            value: '',
            isValid: false
        }
    }, false);

    useEffect(() => {
        const fetchCurrentOffer = async () => {

            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/offers')
                const getCurrentOffer = responseData.data.data.find(offer => offer.currentOffer === true);
                const allOffers = responseData.data.data;

                const blankOffer = {
                    series: '',
                    round: '',
                    max: 0,
                    min: 0,
                    priceShare: 0,
                    totalSharesIssued: 0
                }

                if (!getCurrentOffer) {
                    setCurrentOffer(blankOffer);
                    setNoOffer(true);
                    setIsChecked(false);
                    setOffers(allOffers);
                } else {
                    setCurrentOffer(getCurrentOffer);
                    setIsChecked(getCurrentOffer.currentOffer)
                    setOffers(allOffers)
                }

            } catch (err) {
                console.log(err);
            }

        }

        fetchCurrentOffer();
    }, [sendRequest])


    const saveNewOffer = async () => {
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/offers/`, 'POST', JSON.stringify({
                round: formState.inputs.round.value,
                series: formState.inputs.series.value,
                max: formState.inputs.max.value,
                min: formState.inputs.min.value,
                priceShare: formState.inputs.priceShare.value,
                totalSharesIssued: formState.inputs.totalSharesIssued.value,
                currentOffer: isChecked
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }


    const deleteOffer = async () => {
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/offers/${currentOffer._id}`, 'DELETE', null, {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }


    const saveSelectedOffer = async (id) => {
        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/offers/${id}`, 'PATCH', JSON.stringify({
                round: formState.inputs.round.value,
                series: formState.inputs.series.value,
                max: formState.inputs.max.value,
                min: formState.inputs.min.value,
                priceShare: formState.inputs.priceShare.value,
                totalSharesIssued: formState.inputs.totalSharesIssued.value,
                currentOffer: isChecked
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }



    const getExistingOffer = async (event) => {
        event.preventDefault();

        setCurrentOffer(null)

        const id = event.currentTarget.value;

        try {
            const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/offers/' + id)
            const selectedOffer = responseData.data.data;

            setCurrentOffer(selectedOffer);

        } catch (err) {
            console.log(err);
        }
    }





    const addOffer = () => {
        saveNewOffer();
    }

    const updateOffer = () => {
        saveSelectedOffer(currentOffer._id)
    }

    if (!offers || !currentOffer) {
        return null;
    }

    return (
        <form>
            <div className="select-offer">
                <div className="row">
                    <Input
                        id="selectOffer"
                        element="select"
                        label="Select Offer"
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                        actionChange={(event) => getExistingOffer(event)}

                    >
                        <option value="select-offer">Select Offer</option>
                        {offers.map(offer => {
                            return <option key={offer._id} value={offer._id}>{offer.series} {offer.round}</option>
                        })}
                    </Input>
                </div>
            </div>



            <div className="row">
                <Input
                    id="series"
                    type="text"
                    element="input"
                    label="Series #"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.series}
                />

                <Input
                    id="round"
                    type="text"
                    element="input"
                    label="Round"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.round}
                />

                <Input
                    id="max"
                    type="number"
                    element="input"
                    label="Max. Purchase Amount ($)"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.max}
                />

                <Input
                    id="min"
                    type="number"
                    element="input"
                    label="Min. Purchase Amount ($)"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.min}
                />

                <Input
                    id="priceShare"
                    type="number"
                    element="input"
                    label="Price Per Share ($)"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.priceShare}
                />

                <Input
                    id="totalSharesIssued"
                    type="number"
                    element="input"
                    label="Total Shares Issued"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.totalSharesIssued}
                />

                <Input
                    id="currentOffer"
                    type="checkbox"
                    element="checkbox"
                    label="Set Current Offer"
                    align="left"
                    onInput={() => { }}
                    validators={[]}
                    initialValid={true}
                    initialValue={currentOffer.currentOffer}
                    actionChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isChecked}
                />
            </div>


            {successMsg &&
                (
                    < div className="success-message">
                        <p>Stock offer was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                {!noOffer && <Button onClick={updateOffer}>Update Offer</Button>}
                {!noOffer && <Button onClick={saveNewOffer} size="link" >Save as New Offer</Button>}
                {!noOffer && <Button onClick={deleteOffer} size="link" >Delete Offer</Button>}
                {noOffer && <Button onClick={addOffer}>Add Round</Button>}
            </div>
        </form>
    )
}

export default UpdateOfferToPurchaseStock;