import React, { useContext, useState, useEffect } from 'react';
import Button from '../../../shared/components/UIElements/Button';
import ImageUpload from '../../../shared/components/UIElements/ImageUpload';

import { useForm } from '../../../shared/hooks/form-hook';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const UpdateAccountDocs = props => {

    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    const [successMsg, setSuccessMsg] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setUser(responseData.data.data);
            } catch (err) {
                console.log(err);
            }
        }

        fetchUser();
    }, [sendRequest, auth.userId])

    const [formState, inputHandler] = useForm({
        uploadId: {
            value: null,
            isValid: false
        },
        uploadAccrediationDoc: {
            value: null,
            isValid: false
        }
    }, false)

    const updateDocumentsHandler = async event => {
        event.preventDefault();

        try {

            const formData = new FormData();
            formData.append('uploadId', formState.inputs.uploadId.value);
            formData.append('uploadAccrediationDoc', formState.inputs.uploadAccrediationDoc.value);

            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', formData, {
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);

        } catch (err) {
            console.log(err);
        }
    }

    if (!user) {
        return null;
    }

    return (
        <form onSubmit={updateDocumentsHandler}>
            <div className="row">
                <ImageUpload currentImg={user.uploadId} id="uploadId" onInput={inputHandler} text="Update Your ID" />
                <ImageUpload currentImg={user.uploadAccrediationDoc} id="uploadAccrediationDoc" onInput={inputHandler} text="Update Accreditation Proof" />
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Documents added succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Upload Documents</Button>
            </div>
        </form>
    )
}

export default UpdateAccountDocs;