import React from 'react';
import classes from './InvestmentCard.module.scss';
import numeral from 'numeral';

const EquityCard = props => {

    return (
        <div className={classes.investmentCard}>
            <h3>Transaction Details</h3>
            <table>
                <tbody>
                    <tr>
                        <td>Contract ID</td>
                        <td>{props.contractId}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td className="title-case">{props.status}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{props.name}</td>
                    </tr>
                    <tr>
                        <td>Investment Date</td>
                        <td>{props.date}</td>
                    </tr>
                    <tr>
                        <td>Type of Shares</td>
                        <td className="title-case">{props.shareType}</td>
                    </tr>
                    <tr>
                        <td>Round</td>
                        <td className="title-case">{props.round}</td>
                    </tr>
                    <tr>
                        <td>Number of Shares</td>
                        <td>{numeral(props.noShares).format('0,0')}</td>
                    </tr>
                    <tr>
                        <td>Purchased Price</td>
                        <td>${props.purchasedPrice}</td>
                    </tr>
                    <tr>
                        <td>Transaction Cost</td>
                        <td>${props.transactionCost}</td>
                    </tr>
                    <tr className={classes.equityValue}>
                        <td>$ Gain</td>
                        <td>${numeral((props.noShares * props.currentPricePerShare) - props.purchasedAmount).format('0,0.00')}</td>
                    </tr>
                    <tr className={classes.equityValue}>
                        <td>Equity Value</td>
                        <td>${numeral(props.noShares * props.currentPricePerShare).format('0,0.00')}</td>
                    </tr>

                    {props.admin && 
                        <tr>
                            <td>Admin Actions</td>
                            <td>
                                <a href={`/admin/edit-investment/${props.investmentId}`}>EDIT</a> &nbsp;&nbsp;&nbsp;&nbsp;
                                <button onClick={() => props.deleteTransaction(props.investmentId)}>DELETE</button>
                            </td>
                        </tr>
                    }
                    {/* <tr>
                        <td>Market Share Price</td>
                        <td>${props.marketSharePrice}</td>
                    </tr>
                    <tr>
                        <td>Market Equity Value</td>
                        <td className="green">{props.marketEquityValue}</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default EquityCard;