import React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = props => {
    if (props.href) {
        return (
            <a
                className={`button button--${props.size || 'default'} button--${props.color || 'color'}`}
                href={props.href}
                target={props.target}
            >
                {props.children}
            </a>
        )

    }

    if (props.to) {
        return (
            <Link
                className={`button button--${props.size || 'default'} button--${props.color || 'color'}`}
                to={props.to}
                exact={props.exact}
                target={props.target}
            >
                {props.children}
            </Link>
        )

    }

    return (
        <button
            className={`button button--${props.size || 'default'} button--${props.color || 'color'}`}
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

export default Button;