import React from 'react';
import classes from './HomeStartups.module.scss';


const HomeStartups = props => {
    return (
        <>
            <h2>Followone Start-Ups</h2>

            <div className={classes.startupList}>
                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-business-creation.82c834f5.jpg" alt="Business Creation" />
                    <h4>1. <i className="fal fa-balance-scale" /> Business Creation</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Corporation Creation</li>
                        <li><i className="fal fa-check" /> Article of Incorporation</li>
                        <li><i className="fal fa-check" /> Shareholder Structure</li>
                        <li><i className="fal fa-check" /> By-Law</li>
                    </ul>

                    <p>Creating a corporation is a complicated and lengthy process. Our team of experts is ready to put together a full package for you, helping your business get up and running.</p>
                </div>


                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-business-plan.8934cd8c.jpg" alt="Business Plan" />
                    <h4>2. <i className="fal fa-file-signature" /> Business Documents</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Market Research</li>
                        <li><i className="fal fa-check" /> Business Plan</li>
                        <li><i className="fal fa-check" /> Trademark & Copyright</li>
                        <li><i className="fal fa-check" /> Private Place Memorandum (PPM)</li>
                    </ul>

                    <p>Running a successful business requires funding. We do market research while preparing your business plan, and private place memorandum (PPM). This prepares your business for investors. We cover trademark and copyright legal documents, to make sure that your company is truly yours.</p>
                </div>


                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-branding.6deb7070.jpg" alt="Branding" />
                    <h4>3. <i className="fal fa-fill-drip" /> Branding</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Logo</li>
                        <li><i className="fal fa-check" /> Style Guide</li>
                        <li><i className="fal fa-check" /> Stationary</li>
                    </ul>

                    <p>We bring your brand to life by offering you a personalized, custom logo, that aligns with your vision for success. The style guide contains logo usage, colors, fonts, and design styles bringing the design together beautifully within a website or an Android app.</p>
                </div>


                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-apps.8cb56145.jpg" alt="Apps" />
                    <h4>4. <i className="fal fa-mobile" /> Android App</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Design</li>
                        <li><i className="fal fa-check" /> Content</li>
                        <li><i className="fal fa-check" /> Implementation</li>
                    </ul>
                    <p>Mobile apps are the future. Using the latest technology and design trends, we create apps that enhance the customer experience and broaden your market coverage, while also generating additional sales and sign-ups.</p>
                </div>


                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-websites.efd2c127.jpg" alt="Websites" />
                    <h4>5. <i className="fal fa-browser" /> Website</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Design</li>
                        <li><i className="fal fa-check" /> Content</li>
                        <li><i className="fal fa-check" /> Implementation</li>
                        <li><i className="fal fa-check" /> Transactional Emails</li>
                    </ul>
                    <p>Depending on your needs, we can design a website that lines up with your branding and style guide. Whether you need an e-Commerce website, a presentational website for your business, or a website to support your mobile app, we have you covered.</p>
                </div>


                <div className={classes.startupListBox}>
                    <img src="https://followone.com/static/media/stups-advertising.37d51c39.jpg" alt="Advertising" />
                    <h4>6. <i className="fal fa-rocket" /> Launch & Growth</h4>
                    <ul>
                        <li><i className="fal fa-check" /> Social Media Accounts</li>
                        <li><i className="fal fa-check" /> Online Marketing</li>
                        <li><i className="fal fa-check" /> Search Engine Optimization</li>
                        <li><i className="fal fa-check" /> Promote App Downloads</li>
                    </ul>
                    <p>Your business, website, or app has been created. Great! Now it’s time to launch. We can publicize your business to give you that push through the door. We will optimize your websites for search engines. This will allow us to promote you or your apps through various methods of online advertising. </p>
                </div> 
            </div>
        </>
    )
} 

export default HomeStartups;