import * as actionTypes from '../actions/actionTypes';

const initialState = {
    mainNavToggle: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_MAIN_NAV:
            return {
                ...state,
                mainNavToggle: !state.mainNavToggle
            }
        default:
            return state
    }
}

export default reducer; 