import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../shared/components/UIElements/Button';
import Input from '../../shared/components/UIElements/Input';
import ImageUpload from '../../shared/components/UIElements/ImageUpload';
import classes from './PaymentInfo.module.scss';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../shared/util/validators';
import numeral from 'numeral';

const PaymentInfo = props => {

    const history = useHistory();
    const transactionId = useParams().id;
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    const [transaction, setTransaction] = useState();
    const [paymentType, setPaymentType] = useState();
    const [app, setApp] = useState();

    const [formState, inputHandler] = useForm({
        paymentType: {
            value: '',
            isValid: false
        },
        paymentCheckFront: {
            value: null,
            isValid: false
        },
        paymentCheckBack: {
            value: null,
            isValid: false
        }
    }, false);

    useEffect(() => {
        const fetchTransaction = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions/' + transactionId)
                setTransaction(responseData.data.data.subscriptionAgreement);
            } catch (err) {
                console.log(err);
            }

        }
        fetchTransaction();

        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setApp(responseData.data.data[0]);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();
    }, [sendRequest, transactionId])

    const onPaymentSubmitHandler = async (e) => {
        e.preventDefault();

        try { 

            const formData = new FormData();

            formData.append('paymentCheckFront', formState.inputs.paymentCheckFront.value);
            formData.append('paymentCheckBack', formState.inputs.paymentCheckBack.value);
            formData.append('paymentType', formState.inputs.paymentType.value);
            formData.append('paymentPending', true);

            await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions/' + transactionId, 'PATCH', formData, {
                Authorization: `Bearer ${auth.token}`
            })

            history.push(`/dashboard`)
        } catch (err) {
            console.log(err);
        }
    }

    if (!transaction || !app) {
        return null;
    }

    return (
        <>
            <LoggedInHeader />
            <div className={classes.payment_info}>
                <h4>Choose Your Payment Method</h4>

                <form onSubmit={onPaymentSubmitHandler}>
                    <div className="row-small">
                        <Input
                            id="paymentType"
                            element="select"
                            label="Select Payment Type"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select payment type."
                            actionChange={event => setPaymentType(event.currentTarget.value)}
                        >
                            <option value="select-payment-type">Choose one...</option>
                            {(auth.user.homeCountry === 'USA' || auth.user.businessCountry === 'USA') && <option value="money-order">Money Order</option>}
                            {(auth.user.homeCountry === 'USA' || auth.user.businessCountry === 'USA') && <option value="checks">Checks</option>}
                            <option value="wire-transfer">Wire Transfer</option>
                        </Input>
                    </div>

                    {(paymentType === 'money-order' || paymentType === 'checks') && (
                        <div className={classes.payment_info_box}>
                            <p><strong>Mobile Check Deposit/Money Order</strong></p>

                            <p>Using a phone camera upload a check, money order or cashier check payable to <strong>Followone Inc.</strong><br /><br /></p>

                            <p>Amount: </p> 
                            <h4>${numeral(transaction.transactionPrice).format('0,0.00')}</h4>

                            <div className="row-small">
                                <ImageUpload
                                    id="paymentCheckFront"
                                    onInput={inputHandler}
                                    text="Upload Check Front"
                                />

                                <ImageUpload
                                    id="paymentCheckBack"
                                    onInput={inputHandler}
                                    text="Upload Check Back"
                                />
                            </div>
                        </div>
                    )}

                    {paymentType === 'wire-transfer' && (
                        <div className={classes.payment_info_box}>
                            <p><strong>Wire Transfer Information</strong></p>

                            <table>
                                <tbody>
                                    <tr>
                                        <td>Bank Name:</td>
                                        <td><strong>{app.paymentWireTransfer.bankName}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Account Name:</td>
                                        <td><strong>{app.paymentWireTransfer.accountName}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>ABA/Routing #:</td>
                                        <td><strong>{app.paymentWireTransfer.aba}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Account #:</td>
                                        <td><strong>{app.paymentWireTransfer.account}</strong></td>
                                    </tr>

                                </tbody>
                            </table>

                            <p className="info"><i className="fal fa-info-circle"></i>Your shares will become available once payment clears and your verification is complete.</p>
                        </div>
                    )}

                    <div className="button-content-actions">
                        <Button type="submit" disabled={!formState.isValid && paymentType !== 'wire-transfer'}>Complete Purchase</Button>
                        <Button size="link" to='/dashboard'>Pay Later</Button>
                    </div>
                </form>
            </div>

        </>
    );
}

export default PaymentInfo;