import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
import { COUNTRIES } from '../../../shared/util/countries';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useForm } from '../../../shared/hooks/form-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const UpdateBusinessInfo = () => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [loggedInUser, setLoggedInUser] = useState();
    const [successMsg, setSuccessMsg] = useState(false);

    const [formState, inputHandler] = useForm({
        businessName: {
            value: '',
            isValid: false
        },
        businessEIN: {
            value: '',
            isValid: false
        },
        jobTitle: {
            value: '',
            isValid: false
        },
        businessEmail: {
            value: '', 
            isValid: false
        },
        businessPhone: {
            value: '',
            isValid: false
        },
        businessAddress: {
            value: '',
            isValid: false
        },
        businessAddressLine2: {
            value: '',
            isValid: false
        },
        businessState: {
            value: '',
            isValid: false
        },
        businessCity: {
            value: '',
            isValid: false
        },
        businessZip: {
            value: '',
            isValid: false
        },
        businessCountry: {
            value: '',
            isValid: false
        },
    }, false);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setLoggedInUser(responseData.data.data);
            } catch (err) {
                console.log(err);
            }
        }

        fetchApp();
    }, [sendRequest, auth.userId])

    const updateBusinessInfoHandler = async event => {
        event.preventDefault();

        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify({
                businessName: formState.inputs.businessName.value,
                businessEIN: formState.inputs.businessEIN.value,
                jobTitle: formState.inputs.jobTitle.value,
                businessEmail: formState.inputs.businessEmail.value,
                businessPhone: formState.inputs.businessPhone.value,
                businessAddress: formState.inputs.businessAddress.value,
                businessAddressLine2: formState.inputs.businessAddressLine2.value,
                businessCity: formState.inputs.businessCity.value,
                businessState: formState.inputs.businessState.value,
                businessZip: formState.inputs.businessZip.value,
                businessCountry: formState.inputs.businessCountry.value,
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }

    if (!loggedInUser) {
        return null;
    }

    return (
        <form onSubmit={updateBusinessInfoHandler}>
            <div className="row">

                <Input
                    id="businessName"
                    type="text"
                    element="input"
                    label="Business Name"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessName}
                />

                <Input
                    id="businessEIN"
                    type="text"
                    element="input"
                    label="Business EIN"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessEIN}
                />

                <Input
                    id="jobTitle"
                    type="text"
                    element="input"
                    label="Job Title"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.jobTitle}
                />

                <Input
                    id="businessEmail"
                    type="email"
                    element="input"
                    label="Email Address"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessEmail}
                />

                <Input
                    id="businessPhone"
                    type="text"
                    element="input"
                    label="Phone #"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessPhone}
                />
            </div>

            <div className="row">
                <Input
                    id="businessAddress"
                    type="text"
                    element="input"
                    label="Business Address Line 1"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessAddress}
                />

                <Input
                    id="businessAddressLine2"
                    type="text"
                    element="input"
                    label="Business Address Line 2"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessAddressLine2}
                />

                <Input
                    id="businessCity"
                    type="text"
                    element="input"
                    label="City"
                    errorText="Please enter your city."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessCity}
                />

                <Input
                    id="businessState"
                    type="text"
                    element="input"
                    label="State/Province"
                    errorText="Please enter your state."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessState}
                />

                <Input
                    id="businessZip"
                    type="text"
                    element="input"
                    label="Zip/Postal Code"
                    errorText="Please enter your Zip/Postal Code."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessZip}
                />

                <Input
                    id="businessCountry"
                    element="select"
                    label="Country"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.businessCountry}
                >
                    {COUNTRIES.map(c => <option key={c} value={c}>{c}</option>)}
                </Input>
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Business info was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Now</Button>
            </div>
        </form>
    )
}

export default UpdateBusinessInfo;