import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import classes from './Dashboard.module.scss';

import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import DashboardSidebar from './Components/DashboardSidebar';
import CompanyGrowth from './Components/CompanyGrowth';
import ProfileDetails from './Components/ProfileDetails';
// import NasdaqInfo from './Components/NasdaqInfo';
import UpcomingUpdates from './Components/UpcomingUpdates';
import { useHistory } from "react-router-dom";

const Dashboard = props => {

    const [appDetails, setAppDetails] = useState();
    const [user, setUser] = useState();
    const [offer, setOffer] = useState();
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const history = useHistory();

    const urlId = history.location.search.replace('?id=', '');

    // console.log(auth);

    let userId;

    if ( urlId && auth.user.role === 'superadmin') {
        userId = urlId;
    } else {
        userId = auth.userId
    }

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setAppDetails(responseData.data.data[0]);
            } catch (err) {
                console.log(err);
            }

        }
        fetchApp();

        const fetchUser = async () => {
            if (userId) {
                try {
                    const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`)
                    setUser(responseData.data.data);
                } catch (err) {
                    console.log(err);
                }
            }
        }



        fetchUser();

        const fetchOffers = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/offers/current/offer')
                setOffer(responseData.data.data);
            } catch (err) {
                console.log(err);
            }

        }
        fetchOffers();
    }, [sendRequest, userId])

    const [dashboardNav, setDashboardNav] = useState({
        profile: true,
        company: false,
        nasdaq: false,
        updates: false
    })

    const showTab = (tab1, tab2, tab3, tab4) => {
        setDashboardNav({ [tab1]: true, [tab2]: false, [tab3]: false, [tab4]: false })
    }

    if (!user || !appDetails || !offer) {
        return null;
    }

    return (
        <div className={classes.accountPage}>
            <LoggedInHeader />

            <div className={classes.dashboardGradient}></div>
            <h4 className={classes.accountPageTitle}>Dashboard</h4>
            <div className={classes.accountPageContainer}>
                <div className={classes.accountPageContent} style={ dashboardNav.profile === false ? {width: '100%'} : {}}>
                    <div className={classes.accountPageContentNav}>
                        <button
                            className={dashboardNav.profile ? classes.active : null}
                            onClick={() => { showTab('profile', 'company', 'nasdaq', 'updates') }}
                        ><i className="fal fa-user"></i>Profile<br /> Details</button>

                        <button
                            className={dashboardNav.company ? classes.active : null}
                            onClick={() => { showTab('company', 'profile', 'nasdaq', 'updates') }}
                        ><i className="fal fa-chart-line"></i>Company<br /> Growth</button>

                        {/* <button
                            className={dashboardNav.nasdaq ? classes.active : null}
                            onClick={() => { showTab('nasdaq', 'company', 'profile', 'updates') }}
                        ><i className="fal fa-info-circle"></i>Nasdaq<br /> Info</button> */}

                        <button
                            className={dashboardNav.updates ? classes.active : null}
                            onClick={() => { showTab('updates', 'company', 'nasdaq', 'profile') }}
                        ><i className="fal fa-code-merge"></i>Press<br /> Releases</button>
                    </div>

                    <div className={classes.accountPageContentBox}>
                        <ProfileDetails active={dashboardNav.profile} app={appDetails} user={user} offer={offer} />
                        <CompanyGrowth active={dashboardNav.company} />
                        {/* <NasdaqInfo active={dashboardNav.nasdaq} /> */}
                        <UpcomingUpdates active={dashboardNav.updates} />
                    </div>
                </div>

                {dashboardNav.profile === true && 
                    <DashboardSidebar app={appDetails} user={user} offer={offer} />
                }
            </div>
        </div >
    );
}

export default Dashboard;