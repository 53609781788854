import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../../shared/components/UIElements/Input';
import Button from '../../../../shared/components/UIElements/Button';

import { useHttpClient } from '../../../../shared/hooks/http-hook';
import { useForm } from '../../../../shared/hooks/form-hook';
import { AuthContext } from '../../../../shared/context/auth-context';


const UpdateMarketCapitalization = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    const [appDetails, setAppDetails] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const [outstandingCommonShares, setOutstandingCommonShares] = useState(0);
    const [formState, inputHandler] = useForm({
        authorizedCommonShares: {
            value: '',
            isValid: false
        },
        outstandingCommonShares: {
            value: '',
            isValid: false
        },
    }, false);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setAppDetails(responseData.data.data[0]);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();

        const fetchTransactions = async () => {
            const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions');

            let totalShares = 0;
        
            responseData.data.data.forEach(t => {
                if (t.typeOfShare === 'common' && t.transactionStatus === 'completed') {
                    totalShares = totalShares + t.noOfShares;
                }
            })
        
            setOutstandingCommonShares(totalShares);
        }

        fetchTransactions();
    }, [sendRequest])

    const updateMarketCapitalizationSubmitHandler = async event => {
        event.preventDefault();

        try {
            await sendRequest(process.env.REACT_APP_BACKEND_URL + `/app/${appDetails._id}`, 'PATCH', JSON.stringify({
                authorizedCommonShares: formState.inputs.authorizedCommonShares.value,
                outstandingCommonShares: formState.inputs.outstandingCommonShares.value
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
            setSuccessMsg(true);
        } catch (err) {
            console.log(err);
        }
    }


    if (!appDetails || !outstandingCommonShares) {
        return null;
    }


    return (
        <form onSubmit={updateMarketCapitalizationSubmitHandler}>
            <div className="subscriber-type">
                <div className="row">
                    <Input
                        id="authorizedCommonShares"
                        type="number"
                        element="input"
                        label="Authorized Common Shares"
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                        initialValue={appDetails.authorizedCommonShares}
                    />
                </div>

                <div className="row">
                    <Input
                        id="outstandingCommonShares"
                        type="number"
                        element="input"
                        label="Outstanding Common Shares"
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                        initialValue={outstandingCommonShares}
                    />
                </div>

                {successMsg &&
                    (
                        < div className="success-message">
                            <p>Market capitalization updated succesfuly.</p>
                            <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                        </div>
                    )
                }

                <div className="button-holder">
                    <Button type="submit">Save Now</Button>
                </div>
            </div>
        </form>
    )
}

export default UpdateMarketCapitalization;