import React, { useState, useContext } from 'react';
import CreateAccountNav from './CreateAccountNav';
import './Steps.scss';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
import { useHistory } from 'react-router-dom';
import LoggedInHeader from '../../../shared/components/Navigation/LoggedInHeader';
import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinnder from '../../../shared/components/UIElements/LoadingSpinner';

import { useForm } from '../../../shared/hooks/form-hook';

// { _id: ObjectId('6171ccb22f8f3626540aad0d')}

const Step2 = props => {

    const auth = useContext(AuthContext);
    const history = useHistory();
    const { sendRequest } = useHttpClient();
    const [isCompany, setIsCompany] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formState, inputHandler] = useForm({
        accreditationStatus: {
            value: '',
            isValid: true
        },
        subscriberType: {
            value: '',
            isValid: true
        },
        subscriberEntityFormData: {
            value: '',
            isValid: true
        },
        purposeOfSubscriptionTrue: {
            value: '',
            isValid: true
        },
        purposeOfSubscriptionFalse: {
            value: '',
            isValid: true
        },
    });

    const submitAccredidationHandler = async () => {
        const data = {
            accreditationStatus: isChecked,
            subscriberType: formState.inputs.subscriberType.value,
            subscriberEntityFormData: formState.inputs.subscriberEntityFormData.value,
            purposeOfSubscriptionTrue: formState.inputs.purposeOfSubscriptionTrue.value,
            purposeOfSubscriptionFalse: formState.inputs.purposeOfSubscriptionFalse.value,
        }

        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify(data), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
        } catch (err) {
            console.log(err);
        }

        history.push('/dashboard');
    }

    const setSubscriberTypeHandler = (event) => {

        let type = event.currentTarget.value;

        if (type !== "individual") {
            setIsCompany(true)
        } else {
            setIsCompany(false)
        }
    }

    // if (!props.active) {
    //     return null;
    // }

    return (

        <div className="create-account">
            <LoggedInHeader />

            <div className="create-account-content">
            <div className="create-account-content-box">
            <CreateAccountNav
                CreatAccountStepTitle="Investor Accreditation Verification"
                // CreateAccountStatusClassStep1="status-complete"
                // CreateAccountStatusClassStep2="status-current"
                // CreateAccountStatusClassStep3="status-incomplete"
                // CreateAccountStatusClassStep4="status-incomplete"
                // CreateAccountStatusClassStep5="status-incomplete"
            />

            <div className="accreditation-disclaimer">
                Rule 501(a) of Regulation D defines an “accredited investor” as any person who comes within any of the following categories, or whom the issuer reasonably believes comes within any of the following categories, at the time of the sale of the securities to that person:
                </div>

            <div className="accreditation-disclaimer">
                To qualify as an accredited investor, you must meat at least one of the conditions bellow:
                </div>

            <ul className="accreditation-list">
                <li>A natural person who had an individual income in excess of $200,000, or joint income with that person’s spouse in excess of $300,000, in each of the two most recent years and reasonably expects to reach the same income level in the current year.</li>
                <li>A natural person whose net worth, either individually or jointly with such person’s spouse, at the time of Subscriber’s purchase, exceeds $1,000,000.</li>
                <li>A bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act, whether acting in its individual or fiduciary capacity.</li>
                <li>A broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of 1934, as amended (the “Exchange Act”).</li>
                <li>An insurance company as defined in section 2(13) of the Exchange Act.</li>
                <li>An investment company registered under the Investment Company Act of 1940 or a business development company as defined in Section 2(a)(48) of that Act.</li>
                <li>A Small Business Investment Company licensed by the U.S. Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958.</li>
                <li>A plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state, or its political subdivisions for the benefit of its employees, if such plan has total assets in excess of $5,000,000.</li>
                <li>An employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974, if the investment decision is made by a plan fiduciary, as defined in Section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons that are accredited investors.</li>
                <li>A private business development company as defined in Section 202(a)(22) of the Investment Advisers Act of 1940.</li>
                <li>An organization described in Section 501(c)(3) of the Internal Revenue Code, or a corporation, business trust or partnership, not formed for the specific purpose of acquiring the Shares, with total assets in excess of $5,000,000.</li>
                <li>A director or executive officer of the Company.</li>
                <li>A trust, with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the Shares, whose purchase is directed by a sophisticated person who has such knowledge and experience in financial and business matters that such person is capable of evaluating the merits and risks of investing in the Company.</li>
                <li>An entity in which all of the equity owners qualify under any of the above subparagraphs.</li>
            </ul>

            <div className="accreditation-acceptance">
                <Input
                    id="accreditationStatus"
                    type="checkbox"
                    element="checkbox"
                    label="By checking this box you certify that you are a qualified accredited investor according to at least one condition above."
                    onInput={inputHandler}
                    initialValid={true}
                    validators={[]}
                    actionChange={(event) => setIsChecked(event.currentTarget.checked)}
                />
            </div>

            <div className="accreditation-disclaimer">
                <p>Type of Subscriber. Indicate the form of entity of Subscriber:</p>
            </div>


            <div className="row">
                <Input
                    id="subscriberType"
                    element="select"
                    label="Select Type of Subscriber"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    actionChange={(event) => setSubscriberTypeHandler(event)}
                >
                    <option value="select-investors-type">Choose one...</option>
                    <option value="individual">Individual</option>
                    <option value="corporation">Corporation</option>
                    <option value="limited-partnership">Limited Partnership</option>
                    <option value="general-partnership">General Partnership</option>
                    <option value="revocable-trust">Revocable Trust</option>
                    <option value="other">Other</option>
                </Input>
            </div>

            {isCompany && (
                <>
                    <div className="accreditation-disclaimer">
                        <p>If Subscriber is not an individual, indicate the approximate date Subscriber entity was formed:</p>
                    </div>

                    <div className="row">
                        <Input
                            id="subscriberEntityFormData"
                            type="date"
                            element="input"
                            label="Entity Formation Date"
                            onInput={inputHandler}
                            initialValid={true}
                            validators={[]}
                        />
                    </div>

                    <div className="accreditation-disclaimer">
                        <p>If Subscriber is not an individual, <strong>initial</strong> the line below which correctly describes the application of the following statement to Subscriber’s situation: Subscriber (x) was not organized or reorganized for the specific purpose of acquiring the Shares and (y) has made investments prior to the date hereof, and each beneficial owner thereof has and will share in the investment in proportion to his or her ownership interest in Subscriber.</p>
                    </div>

                    <div className="row">
                        <Input
                            id="purposeOfSubscriptionTrue"
                            type="text"
                            element="input"
                            label="True"
                            onInput={inputHandler}
                            initialValid={true}
                            validators={[]}
                        />
                        <Input
                            id="purposeOfSubscriptionFalse"
                            type="text"
                            element="input"
                            label="False"
                            onInput={inputHandler}
                            initialValid={true}
                            validators={[]}
                        />
                    </div>

                    <div className="accreditation-disclaimer">
                        <p>If the “False” box is checked, each person participating in the entity will be required to fill out a Subscription Agreement.</p>
                    </div>
                </>
            )}


            <div className="button-holder-previous">
                {/* <Button color="white" onClick={props.previous}>Previous</Button> */}
            </div>
            <div className="button-holder-next">
                <Button onClick={submitAccredidationHandler} disabled={!formState.isValid}>Save</Button>
            </div>
        </div>
            </div>
        </div>

        
    );
}

export default Step2;