import React, { useState, useContext } from 'react';
import CreateAccountNav from './CreateAccountNav';
import Input from '../../../shared/components/UIElements/Input';
import './Steps.scss';
import Button from '../../../shared/components/UIElements/Button';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import LoggedInHeader from '../../../shared/components/Navigation/LoggedInHeader';
import { AuthContext } from '../../../shared/context/auth-context';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinnder from '../../../shared/components/UIElements/LoadingSpinner';
import { useForm } from '../../../shared/hooks/form-hook';

const Step5 = props => {

    const auth = useContext(AuthContext);
    const history = useHistory();
    const { sendRequest } = useHttpClient();
    const [formState, inputHandler] = useForm({
        // agreementCheckBox: {
        //     value: '',
        //     isValid: false
        // },
        investorType: {
            value: '',
            isValid: false
        },
        investmentStage: {
            value: '',
            isValid: false
        },
        investmentSector: {
            value: '',
            isValid: false
        },
        estInvestmentAmount: {
            value: '',
            isValid: false
        },
        invCompanyName1: {
            value: '',
            isValid: false
        },
        invAmount1: {
            value: '',
            isValid: false
        },
        invYear1: {
            value: '',
            isValid: false
        },
        invCompanyName2: {
            value: '',
            isValid: false
        },
        invAmount2: {
            value: '',
            isValid: false
        },
        invYear2: {
            value: '',
            isValid: false
        },
        invCompanyName3: {
            value: '',
            isValid: false
        },
        invAmount3: {
            value: '',
            isValid: false
        },
        invYear3: {
            value: '',
            isValid: false
        }
    }, false);

    const [prevInvest, setPrevInvest] = useState(false);

    const yesPrevInvest = () => {
        setPrevInvest(true);
    }

    const noPrevInvest = () => {
        setPrevInvest(false);
    }

    // const [isChecked, setIsChecked] = useState(false);
    // const [formReady, setFormReady] = useState(false);

    // const toggleFormReady = (event) => {
    //     // setFormReady(!formReady);
    //     // setIsChecked(!isChecked)
    // }


    const savePrevInvestHandler = async () => {

        const data = {
            // agreementCheckBox: isChecked,
            investorType: formState.inputs.investorType.value,
            investmentStage: formState.inputs.investmentStage.value,
            investmentSector: formState.inputs.investmentSector.value,
            estInvestmentAmount: formState.inputs.estInvestmentAmount.value,
            invCompanyName1: formState.inputs.invCompanyName1.value,
            invAmount1: formState.inputs.invAmount1.value,
            invYear1: formState.inputs.invYear1.value,
            invCompanyName2: formState.inputs.invCompanyName2.value,
            invAmount2: formState.inputs.invAmount2.value,
            invYear2: formState.inputs.invYear2.value,
            invCompanyName3: formState.inputs.invCompanyName3.value,
            invAmount3: formState.inputs.invAmount3.value,
            invYear3: formState.inputs.invYear3.value,
        }

        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify(data), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
        } catch (err) {
            console.log(err);
        }

        history.push('/dashboard');
    }




    // if (!props.active) {
    //     return null;
    // }

    return (

        <div className="create-account">
            <LoggedInHeader />

            <div className="create-account-content">
            <div className="create-account-content-box">
            <CreateAccountNav
                CreatAccountStepTitle="Investor Profile"
                // CreateAccountStatusClassStep1="status-complete"
                // CreateAccountStatusClassStep2="status-complete"
                // CreateAccountStatusClassStep3="status-complete"
                // CreateAccountStatusClassStep4="status-complete"
                // CreateAccountStatusClassStep5="status-current"
            />


            <label className="create-account-content-box-label">Previous Investments</label>

            <p className="sing-up-question">Have you made previous investments? </p>

            <div className="row">
                <Input
                    id="yesPrevInvest"
                    type="radio"
                    align="left"
                    element="radio"
                    onInput={() => { }}
                    actionChange={yesPrevInvest}
                    label="Yes"
                    validators={[]}
                    name="investmentHistory"
                />

                <Input
                    id="noPrevInvest"
                    type="radio"
                    align="left"
                    element="radio"
                    onInput={() => { }}
                    actionChange={noPrevInvest}
                    label="No, this will be my first investment."
                    validators={[]}
                    name="investmentHistory"
                />

                {/* <Input
                    id="skipPrevInvest"
                    type="radio"
                    align="left"
                    element="radio"
                    onInput={() => { }}
                    actionChange={noPrevInvest}
                    label="Skip Question."
                    validators={[]}
                    name="investmentHistory"
                /> */}
            </div>




            {prevInvest && (
                <>
                    <label className="create-account-content-box-label">Investment Information</label>

                    <div className="row">

                        <Input
                            id="investorType"
                            element="select"
                            label="Select Investor Type"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        >
                            <option value="select-investors-type">Choose one...</option>
                            <option value="angel-investor">Angel Investor</option>
                            <option value="private-investor">Private Investor</option>
                            <option value="vc-investors">VC Investors</option>
                            <option value="mutual-funds">Mutual Funds</option>
                            <option value="banks">Banks</option>
                        </Input>

                        <Input
                            id="investmentStage"
                            element="select"
                            label="Select Stage of Investment"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        >
                            <option value="select-investment-sector">Choose one...</option>
                            <option value="start-up">Start-up</option>
                            <option value="early-stage">Early Stage</option>
                            <option value="medium-stage">Medium Stage</option>
                            <option value="pre-ipo">Pre IPO</option>
                            <option value="public-company">Public Company</option>
                        </Input>

                        <Input
                            id="investmentSector"
                            element="select"
                            label="Select Stage of Investment"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        >
                            <option value="select-investment-sector">Select Investment Sector</option>
                            <option value="consumer-discretionary">Consumer Discretionary</option>
                            <option value="consumer-staples">Consumer Staples</option>
                            <option value="financials">Financials</option>
                            <option value="health-care">Health Care</option>
                            <option value="industrials">Industrials</option>
                            <option value="it">IT Software & Hardware</option>
                            <option value="materials">Materials</option>
                            <option value="real-estate">Real Estate</option>
                            <option value="renewable-energy">Renewable Energy</option>
                            <option value="telecommunication">Telecommunication Services</option>
                            <option value="utilities">Utilities</option>
                        </Input>
                    </div>



                    <div className="row">
                        <Input
                            id="estInvestmentAmount"
                            type="number"
                            element="input"
                            label="Est. Investment Amount"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />
                    </div>


                    <p className="sing-up-question">Please list your 3 previous investments.</p>

                    <div className="row">

                        <Input
                            id="invCompanyName1"
                            type="text"
                            element="input"
                            label="Company Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invAmount1"
                            type="number"
                            element="input"
                            label="Amount"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invYear1"
                            type="text"
                            element="input"
                            label="Year"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                    </div>

                    <div className="row">

                        <Input
                            id="invCompanyName2"
                            type="text"
                            element="input"
                            label="Company Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invAmount2"
                            type="number"
                            element="input"
                            label="Amount"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invYear2"
                            type="text"
                            element="input"
                            label="Year"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                    </div>

                    <div className="row">

                        <Input
                            id="invCompanyName3"
                            type="text"
                            element="input"
                            label="Company Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invAmount3"
                            type="number"
                            element="input"
                            label="Amount"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="invYear3"
                            type="text"
                            element="input"
                            label="Year"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                    </div>
                </>
            )}


            {/* <div className="accreditation-acceptance" style={{ marginTop: "2rem" }}>
                <Input
                    id="agreementCheckBox"
                    type="checkbox"
                    element="checkbox"
                    label="By checking this box you agree with the Followone NDA the Consent and to Electronic Signatures and Records in Followone Transactions and digitaly sign the NDA using your First and Last Name."
                    onInput={inputHandler}
                    actionChange={(event) => toggleFormReady(event)}
                    validators={[]}
                />
            </div> */}


            <div className="button-holder-previous">
                {/* <Button color="white" onClick={props.previous}>Previous</Button> */}
            </div>
            <div className="button-holder-next">
                <Button onClick={savePrevInvestHandler}>Save</Button>
            </div>
        </div>
            </div>
        </div>

       
    );
}

export default Step5;