import React, { useContext, useState, useEffect } from 'react';
import classes from './AdminInvestors.module.scss';
import Button from '../../../shared/components/UIElements/Button';
import Modal from '../../../shared/components/UIElements/Modal';
import axios from 'axios';
import numeral from 'numeral';
import moment from 'moment';
import { useHistory } from 'react-router';


import { useHttpClient } from '../../../shared/hooks/http-hook';
// import { useForm } from '../../../shared/hooks/form-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const AdminInvestors = () => {

    const auth = useContext(AuthContext);
    const [users, setUsers] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUser, setDeleteUser] = useState();
    const { sendRequest } = useHttpClient();
    const history = useHistory();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users')
                setUsers(responseData.data.data);
            } catch (err) {
                console.log(err);
            }
        }

        fetchUsers();
    }, [sendRequest])

    const deleteUserModal = async (user) => {
        setDeleteModal(true);
        setDeleteUser(user);
    }

    const closeDeleteUserModal = () => {
        setDeleteModal(false);
    }

    const deleteUserHandler = async () => {
        await axios.delete(process.env.REACT_APP_BACKEND_URL + '/users/' + deleteUser._id);
        setDeleteModal(false);
        window.location.reload(false);
    }

    const changeStatusUserHandler = async () => {
        await axios.patch(process.env.REACT_APP_BACKEND_URL + '/users/status/' + deleteUser._id);
        setDeleteModal(false);
        window.location.reload(false);
    }

    const openUserTransactions = (userId) => {
        history.push(`/equity-investments/${userId}`)
    }

    const openUserDashboard = (userId) => {
        history.push(`/dashboard?id=${userId}`)
    }

    function formatSocialSecurity(val){
        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val;
    }  	

    if (!users) {
        return null;
    }


    const showSSN = (user) => {

        let ssn = '';

        if (user.socialSecurity) {
            if (auth.user.role === 'superadmin') {
                ssn = formatSocialSecurity(user.socialSecurity)
            } else {
                ssn = `***-**-${user.socialSecurity.slice(-4)}`
            }
        }

        return ssn;
    }

   

    

    return (
        <div className={classes.adminContentInvestors}>

            <div className={[classes.investorsList, classes.investTable].join(',').replace(',', ' ')}>
                <table>
                    <thead>
                        <tr>
                            <td>Investors Name</td>
                            <td>Email</td>
                            {/* <td>#Key Buyer</td> */}
                            {/* <td>#Account ID</td> */}
                            <td>Accreditation</td>
                            {/* <td>SSN / Passport</td> */}
                            <td>Shares</td>
                            <td>Total Paid</td>
                            <td>Role</td>
                            <td>Status</td>
                            <td>Login</td>
                            <td>User Action</td>
                        </tr>
                    </thead>
 
                    {/* Open Modal with Investor Details */}

                    <tbody>
                        {users.map(user => {
                            return (
                                <tr key={user._id} style={{color: `${user.status ? '#444' : '#ccc'}`}}>
                                    <td>{user.firstName} {user.middleName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                    {/* <td>{user._id}</td> */}
                                    {/* <td>{user.accountId}</td> */}
                                    <td>{user.accreditationStatus ? 'Yes' : 'No'}</td>
                                    {/* <td>{showSSN(user)}{user.passportNo}</td> */}
                                    <td>{numeral(user.totalShares).format('0,0')} </td>
                                    <td>${numeral(user.investmentCost).format('0,0.00')}</td>
                                    <td>{user.role}</td>
                                    <td>{user.status ? 'Active' : 'Inactive'}</td>
                                    <td>{user.lastLogin ? moment(user.lastLogin).format('MM DD YY | h:mm a') : '-'} - {user.totalLogins}</td>
                                    <td>
                                        <Button size="icon" onClick={() => openUserDashboard(user._id)}><i className="fal fa-browser"></i></Button>
                                        <Button size="icon" onClick={() => openUserTransactions(user._id)}><i className="fal fa-user-tie"></i></Button>
                                        <Button size="icon"><i className="fal fa-edit"></i></Button>
                                        <Button size="icon" color="red" onClick={() => deleteUserModal(user)}><i className="fal fa-user-cog"></i></Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {deleteModal && 
                <Modal
                    show={deleteModal}
                    onCancel={closeDeleteUserModal}
                    header="Delete User"
                    size="regular"
                >
                    <div style={{textAlign: 'center'}}>
                      <h4> Are you sure you want to delete {deleteUser.firstName} {deleteUser.lastName} ? </h4>
                      <br />
                      <Button color="red" onClick={changeStatusUserHandler}>SET {deleteUser.status ?  'INACTIVE' : 'ACTIVE'}</Button>
                      <Button color="white" onClick={deleteUserHandler}>DELETE USER</Button>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default AdminInvestors;