import React from 'react';
import './CreateAccountNav.scss';

const CreateAccountNav = props => {
    return (
        <div className="create-account-nav">
            <span className="create-account-nav-title">{props.CreatAccountStepTitle}</span>
            {/* <ul>
                <li className={props.CreateAccountStatusClassStep1}><span>1</span></li>
                <li className={props.CreateAccountStatusClassStep2}><span>2</span></li>
                <li className={props.CreateAccountStatusClassStep3}><span>3</span></li>
                <li className={props.CreateAccountStatusClassStep4}><span>4</span></li>
                <li className={props.CreateAccountStatusClassStep5}><span>5</span></li>
            </ul> */}
        </div>
    );
}

export default CreateAccountNav; 