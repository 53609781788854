import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import classes from './Nav.module.scss';

import * as uiActions from '../../store/actions';
import Button from '../UIElements/Button';
import { AuthContext } from '../../context/auth-context';

const Menu = props => {

    const dispatch = useDispatch();
    const onMenuCloseButton = () => dispatch(uiActions.toggleMainNav());

    const auth = useContext(AuthContext);

    if (!props.active) {
        return null;
    }

    return (
        <div className={classes.menu}>

            <button className={classes.navigation_button} onClick={onMenuCloseButton}>
                <i className="fal fa-times"></i>
            </button>

            <ul onClick={onMenuCloseButton}>
                <li><h4>MENU</h4></li>
                <li><NavLink activeClassName={classes.active} exact to="/"><i className="fal fa-home-lg"></i> Home</NavLink></li>
                <li><NavLink activeClassName={classes.active} exact to="/dashboard"><i className="fal fa-columns"></i> Dashboard</NavLink></li>
                <li><NavLink activeClassName={classes.active} exact to="/equity-investments"><i className="fal fa-chart-line"></i> Equity Investments</NavLink></li>
                {auth.user.hasRevenueInvestment && <li><NavLink activeClassName={classes.active} exact to="/revenue-investments"><i className="fal fa-chart-bar"></i> Revenue Investments</NavLink></li> }
                <li><NavLink activeClassName={classes.active} exact to="/transactions"><i className="fal fa-usd-circle"></i> Transactions</NavLink></li>
                <li><NavLink activeClassName={classes.active} exact to="/profile-settings"><i className="fal fa-clipboard-user"></i> Profile Settings</NavLink></li>
                {auth.user.role === "superadmin" ? <li><NavLink activeClassName={classes.active} exact to="/admin"><i className="fal fa-cogs"></i> Superadmin</NavLink></li> : null}
                <li style={{ paddingTop: '20px' }}><Button color="white" onClick={auth.logout}><i className="fal fa-sign-out"></i> &nbsp;Sign Out</Button></li>
            </ul>
        </div>
    );

}

export default Menu;