import React, { useContext } from 'react';
import CreateAccountNav from './CreateAccountNav';
import Input from '../../../shared/components/UIElements/Input';
import './Steps.scss';
import Button from '../../../shared/components/UIElements/Button';
import { useHistory } from 'react-router-dom';
import LoggedInHeader from '../../../shared/components/Navigation/LoggedInHeader';
import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinnder from '../../../shared/components/UIElements/LoadingSpinner';
import { COUNTRIES } from '../../../shared/util/countries';

import { VALIDATOR_MAXLENGTH, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from '../../../shared/util/validators';
import { useForm } from '../../../shared/hooks/form-hook';

const Step3 = props => {

    const auth = useContext(AuthContext);
    const history = useHistory();
    const { sendRequest } = useHttpClient();
    const [formState, inputHandler] = useForm({
        cellularNo: {
            value: '',
            isValid: false
        },
        dob: {
            value: '',
            isValid: true
        },
        socialSecurity: {
            value: '',
            isValid: false
        },
        homeAddress: {
            value: '',
            isValid: false
        },
        homeAddressLine2: {
            value: '',
            isValid: false
        },
        homeCity: {
            value: '',
            isValid: false
        },
        homeState: {
            value: '',
            isValid: false
        },
        homeZip: {
            value: '',
            isValid: false
        },
        homeCountry: {
            value: '',
            isValid: false
        },
        spouseFirstName: {
            value: '',
            isValid: true
        },
        spouseLastName: {
            value: '',
            isValid: true
        },
        spouseDob: {
            value: '',
            isValid: true
        },
        passportNo: {
            value: '',
            isValid: true
        }
    }, false);

    const savePersonalInforHandler = async () => {
        const data = {
            cellularNo: formState.inputs.cellularNo.value,
            dob: formState.inputs.dob.value,
            socialSecurity: formState.inputs.socialSecurity.value,
            passportNo: formState.inputs.passportNo.value,
            homeAddress: formState.inputs.homeAddress.value,
            homeAddressLine2: formState.inputs.homeAddressLine2.value,
            homeCity: formState.inputs.homeCity.value,
            homeState: formState.inputs.homeState.value,
            homeZip: formState.inputs.homeZip.value,
            homeCountry: formState.inputs.homeCountry.value,
            spouseFirstName: formState.inputs.spouseFirstName.value,
            spouseLastName: formState.inputs.spouseLastName.value,
            spouseDob: formState.inputs.spouseDob.value,
            personalInfoCheck: true, 
        }

        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify(data), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })
        } catch (err) {
            console.log(err);
        }

        history.push('/dashboard');
    }

    return (

        <div className="create-account">
            <LoggedInHeader />

            <div className="create-account-content">
            <div className="create-account-content-box">
            <CreateAccountNav
                CreatAccountStepTitle="Personal Information"
                // CreateAccountStatusClassStep1="status-complete"
                // CreateAccountStatusClassStep2="status-complete"
                // CreateAccountStatusClassStep3="status-current"
                // CreateAccountStatusClassStep4="status-incomplete"
                // CreateAccountStatusClassStep5="status-incomplete"
            />

          

            <label className="create-account-content-box-label">Home Address</label>

            <div className="row">
                <Input
                    id="homeAddress"
                    type="text"
                    element="input"
                    label="Home Address Line 1"
                    errorText="Please enter your home address."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                <Input
                    id="homeAddressLine2"
                    type="text"
                    element="input"
                    label="Home Address Line 2"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                />

                <Input
                    id="homeCity"
                    type="text"
                    element="input"
                    label="City"
                    errorText="Please enter your city."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

            </div>

            <div className="row">

                <Input
                    id="homeState"
                    type="text"
                    element="input"
                    label="State/Province"
                    errorText="Please enter your state."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                <Input
                    id="homeZip"
                    type="text"
                    element="input"
                    label="Zip/Postal Code"
                    errorText="Please enter your Zip/Postal Code."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                {/* <Input
                    id="homeCountry"
                    type="text"
                    element="input"
                    label="Country"
                    errorText="Please enter your country."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                /> */}

                <Input
                    id="homeCountry"
                    element="select"
                    label="Country"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                >
                    {COUNTRIES.map(c => <option key={c} value={c}>{c}</option>)}
                </Input>

            </div>

            <label className="create-account-content-box-label">Personal Information</label>

            <div className="row">
                <Input
                    id="cellularNo"
                    type="number"
                    element="input"
                    label="Cellular #"
                    errorText="Please enter a valid cell phone #."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                <Input
                    id="dob"
                    type="date"
                    element="input"
                    label="Date of Birth"
                    errorText="Please enter a valid date of birth."
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                />

                {formState.inputs.homeCountry.value === 'USA' &&
                    <Input
                        id="socialSecurity"
                        type="password"
                        element="input"
                        label="Social Security #"
                        errorText="Please enter a valid social security number."
                        onInput={inputHandler}
                        validators={[VALIDATOR_MAXLENGTH(9), VALIDATOR_MINLENGTH(9)]}
                        initialValid={true}
                    />
                }

                {!!formState.inputs.homeCountry.value && formState.inputs.homeCountry.value !== 'USA' &&
                    <Input
                        id="passportNo"
                        type="text"
                        element="input"
                        label="Passport #"
                        errorText="Please enter a valid passport number."
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                    />
                }

            </div>

            {auth.user && auth.user.subscriberType  === 'individual' &&
                <>
                    <label className="create-account-content-box-label">Spouse (Optional)</label>

                    <div className="row">
                        <Input
                            id="spouseFirstName"
                            type="text"
                            element="input"
                            label="First Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />

                        <Input
                            id="spouseLastName"
                            type="text"
                            element="input"
                            label="Last Name"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        /> 

                        <Input
                            id="spouseDob"
                            type="date"
                            element="input"
                            label="Date of Birth"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                        />
                    </div>
                </>
            } 



            <div className="button-holder-previous">
                {/* <Button color="white" onClick={props.previous}>Previous</Button> */}
            </div>
            <div className="button-holder-next">
                <Button disabled={!formState.isValid} onClick={savePersonalInforHandler}>Save</Button>
            </div>
        </div>
            </div>
        </div>

        
    );
}

export default Step3;