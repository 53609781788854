import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
import { COUNTRIES } from '../../../shared/util/countries';
// import ErrorModal from '../../../shared/components/Modals/Error';
// import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useForm } from '../../../shared/hooks/form-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const UpdatePersonalInfo = () => {

    const auth = useContext(AuthContext);
    const [loggedInUser, setLoggedInUser] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const { sendRequest } = useHttpClient();

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
 
        return [year, month, day].join('-');
    }

    const [formState, inputHandler] = useForm({
        cellularNo: {
            value: '',
            isValid: false
        },
        dob: {
            value: '',
            isValid: false
        },
        socialSecurity: {
            value: '',
            isValid: false
        },
        homeAddress: {
            value: '',
            isValid: false
        },
        homeAddressLine2: {
            value: '',
            isValid: false
        },
        homeCity: {
            value: '',
            isValid: false
        },
        homeState: {
            value: '',
            isValid: false
        },
        homeZip: {
            value: '',
            isValid: false
        },
        homeCountry: {
            value: '',
            isValid: false
        },
        passportNo: {
            value: '',
            isValid: true
        }
    }, false);

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`)
                setLoggedInUser(responseData.data.data);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();
    }, [sendRequest, auth.userId])

    const updatePersonalInfoHandler = async event => {
        event.preventDefault();
        try {
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}`, 'PATCH', JSON.stringify({
                cellularNo: formState.inputs.cellularNo.value,
                dob: formState.inputs.dob.value,
                socialSecurity: formState.inputs.socialSecurity.value,
                passportNo: formState.inputs.passportNo.value,
                homeAddress: formState.inputs.homeAddress.value,
                homeAddressLine2: formState.inputs.homeAddressLine2.value,
                homeCountry: formState.inputs.homeCountry.value,
                homeCity: formState.inputs.homeCity.value,
                homeState: formState.inputs.homeState.value,
                homeZip: formState.inputs.homeZip.value,
            }), {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.token}`
            })

            setSuccessMsg(true);

        } catch (err) {
            console.log(err);
        }
    }

    if (!loggedInUser) {
        return null;
    }

    return (
        <form onSubmit={updatePersonalInfoHandler}>
            <div className="row">
                <Input
                    id="homeAddress"
                    type="text"
                    element="input"
                    label="Home Address Line 1"
                    errorText="Please enter your home address."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.homeAddress}
                />

                <Input
                    id="homeAddressLine2"
                    type="text"
                    element="input"
                    label="Home Address Line 2"
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.homeAddressLine2}
                />

                <Input
                    id="homeCity"
                    type="text"
                    element="input"
                    label="City"
                    errorText="Please enter your city."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.homeCity}
                />

                <Input
                    id="homeState"
                    type="text"
                    element="input"
                    label="State/Province"
                    errorText="Please enter your state."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.homeState}
                />

                <Input
                    id="homeZip"
                    type="text"
                    element="input"
                    label="Zip/Postal Code"
                    errorText="Please enter your Zip/Postal Code."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.homeZip}
                />

                <Input
                    id="homeCountry"
                    element="select"
                    label="Country"
                    onInput={inputHandler}
                    validators={[]}
                    initialValue={loggedInUser.homeCountry}
                    initialValid={true}
                >
                    {COUNTRIES.map(c => <option key={c} value={c}>{c}</option>)}
                </Input>
            </div>

            <div className="row">
                <Input
                    id="cellularNo"
                    type="number"
                    element="input"
                    label="Cellular #"
                    errorText="Please enter a valid cell phone #."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={loggedInUser.cellularNo}
                />

                <Input
                    id="dob"
                    type="date"
                    element="input"
                    label="Date of Birth"
                    errorText="Please enter a valid date of birth."
                    onInput={inputHandler}
                    validators={[]}
                    initialValid={true}
                    initialValue={formatDate(loggedInUser.dob)}
                />

                {formState.inputs.homeCountry.value === 'USA' &&
                    <Input
                        id="socialSecurity"
                        type="password"
                        element="input"
                        label="Social Security #"
                        errorText="Please enter your social security number."
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                        initialValue={loggedInUser.socialSecurity}
                    />
                }

                {!!formState.inputs.homeCountry.value && formState.inputs.homeCountry.value !== 'USA' &&
                    <Input
                        id="passportNo"
                        type="text"
                        element="input"
                        label="Passport #"
                        errorText="Please enter a valid passport number."
                        onInput={inputHandler}
                        validators={[]}
                        initialValid={true}
                        initialValue={loggedInUser.passportNo}
                    />
                }
            </div>

            {successMsg &&
                (
                    < div className="success-message">
                        <p>Personal info was updated succesfuly.</p>
                        <Button size="icon" color="transparent-white" onClick={() => setSuccessMsg(false)}><i className="fal fa-times"></i></Button>
                    </div>
                )
            }

            <div className="button-holder">
                <Button type="submit">Save Now</Button>
            </div>
        </form>
    )
}

export default UpdatePersonalInfo;