import React, { useState, useContext, useEffect } from 'react';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import EquityCard from './Components/EquityCard';
import RevenueCard from './Components/RevenueCard.js';
import Button from '../../shared/components/UIElements/Button';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import moment from 'moment';
import numeral from 'numeral';
import { useParams } from 'react-router';

import classes from './Investments.module.scss';

const Investments = props => {

    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [equityInv, setEquityInv] = useState();
    const [revenueInv, setRevenueInv] = useState();
    const [offer, setOffer] = useState();

    let userId = auth.userId;
    let { investorId } = useParams();

    let mainId; 

    if (investorId) {
        mainId = investorId;
    } else {
        mainId = userId;
    }    

    useEffect(() => { 
        if (mainId) {
            const fetchRevenue = async () => {
                try {
                    const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${mainId}/transactions/revenue`)
                    setRevenueInv(responseData.data);
                } catch (err) {
                    console.log(err);
                }

            }
            fetchRevenue();

            const fetchEquity = async () => {
                if (mainId) {
                    try {
                        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${mainId}/transactions/equity`)
                        setEquityInv(responseData.data);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            fetchEquity();
        }

        const fetchOffers = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/offers/current/offer')
                setOffer(responseData.data.data);
            } catch (err) {
                console.log(err);
            }

        }
        fetchOffers();

    }, [sendRequest, mainId])


    const deleteTransactionHandler = async (transId) => {
        await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transactions/${transId}`, 'DELETE')
        window.location.reload(false);
    }

    if (!mainId || !equityInv || !revenueInv || !offer) {
        return null;
    }

    // console.log(auth.user.role);

    return (
        <div className={classes.transactions}>
            <LoggedInHeader />
            {!investorId && (
                <h4>
                    {props.title} 
                    {/* &nbsp;&nbsp;<Button color="green" to="/buy-shares">Buy Shares</Button> */}
                </h4>
            )}
            {investorId && <h4>
                {props.title} &nbsp;&nbsp;
                <Button color="white" to="/admin/investors">Return to Investors</Button> &nbsp;&nbsp;
                <Button color="green" to={`/equity-investments/${investorId}`}>Equity Investments</Button> &nbsp;&nbsp;
                <Button color="green" to={`/revenue-investments/${investorId}`}>Revenue Investments</Button>
                </h4>
            }


            {props.type === "equity" &&
                <div className={classes.investmentList}>
                    {equityInv.transactions.length === 0 ? <p>No transactions found.</p> :
                        equityInv.transactions.map(t => {
                            return <EquityCard
                                key={t._id}
                                investmentId={t._id} 
                                contractId={t.contractId ? t.contractId : t.transferId}
                                status={t.transactionStatus}
                                name={t.subscriptionAgreement.buyerName}
                                date={moment(t.transactionDate).utc().format('MM/DD/YYYY')}
                                shareType={t.typeOfShare}
                                round={t.round.replace('-', ' ')}
                                noShares={t.noOfShares}
                                purchasedPrice={numeral(t.pricePerShare).format('0,0.00')}
                                purchasedAmount={t.transactionPrice}
                                transactionCost={numeral(t.transactionPrice).format('0,0.00')}
                                currentPricePerShare={offer.priceShare}
                                deleteTransaction={deleteTransactionHandler}
                                admin={auth.user.role === 'superadmin'}
                            // marketSharePrice="20.00"
                            // marketEquityValue="20,000,000"
                            />
                        })
                    }

                </div>}

            {props.type === "revenue" &&
                <div className={classes.investmentList}>
                    {revenueInv.transactions.length === 0 ? <p>No transactions found.</p> :
                        revenueInv.transactions.map(t => {
                            return <RevenueCard
                                key={t._id}
                                investmentId={t._id} 
                                contractId={t.contractId ? t.contractId : t.transferId}
                                status={t.transactionStatus}
                                name={t.subscriptionAgreement.buyerName}
                                date={moment(t.transactionDate).utc().format('MM/DD/YYYY')}
                                endDate={moment(t.revContractEndDate).format('MM/DD/YYYY')}
                                contractLength={t.revContractLength}
                                monthlyRevenue={numeral(t.revContractPerMonth).format('0,0.00')}
                                contractAmount={numeral(t.revContractAmount).format('0,0.00')}
                                contractPrice={t.revContractAmount}
                                shareType={t.typeOfShare}
                                noShares={t.noOfShares}
                                currentPricePerShare={offer.priceShare}
                                deleteTransaction={deleteTransactionHandler}
                                admin={auth.user.role === 'superadmin'}
                            // marketSharePrice="20.00"
                            // marketEquityValue="600,000"
                            />
                        })
                    }
                </div>}
        </div>
    )
}

export default Investments;