import React from 'react';
import classes from './AdminNav.module.scss';
import Button from '../../../shared/components/UIElements/Button';

const AdminNav = props => {
    return (
        <div className={classes.adminContentNav}>
            <Button to="/admin/transactions">Transactions</Button>
            <Button to="/admin/investors">Investors</Button>
            <Button to="/admin/add-investment">Add Investment</Button>
            <Button to="/admin/add-investor">Add Investor</Button>
            <Button color="red" to="/admin/pending-investments">Pending Investment</Button>
            <Button to="/admin/status">Status</Button>
            <Button to="/admin/settings">Settings</Button>
        </div>
    );
}
export default AdminNav