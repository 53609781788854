import React from 'react';
import classes from './HomeTech.module.scss';


const HomeTech = props => {
    return (
        <>
            <h2>Technology</h2>

            <div className={classes.homeTech}>
                <p>Followone, a SaaS Company, is powered by the latest versions of React.JS and Node.JS. This technology allows our users to e xperience a great performance and security while giving us the reliability and scalability we need to grow and expand our business.</p>

                <p>Our business model places the user at the center of our design. Usability, findability, consistancy and simplicity are our main engines that driv e the current and future v ersions of our platform. Whether you are using our website, Android or iOS application, y ou will enjoy the same great experience regardless of device.</p>

                <div>
                    <i className="fa-brands fa-app-store"></i>
                    <i className="fa-brands fa-google-play"></i>
                    <i className="fa-brands fa-js"></i>
                    <i className="fa-brands fa-node"></i>
                    <i className="fa-brands fa-react"></i>
                    <i className="fa-brands fa-npm"></i>
                    <i className="fa-brands fa-sass"></i>
                    <i className="fa-solid fa-database"></i>
                    <i className="fa-solid fa-server"></i>
                </div>  
            </div>
        </>
    )
}

export default HomeTech;