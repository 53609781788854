import React from 'react';
import { createRoot } from 'react-dom/client';


import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { BrowserRouter } from 'react-router-dom';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

import designReducer from './shared/store/reducers/ui';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container); 

const rootReducer = combineReducers({
    ui: designReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

root.render(app);










