import React, { useEffect, useState } from 'react';
// import Button from '../../shared/components/UIElements/Button';
import moment from 'moment'

const NdaDocument = props => {

    const [ fullName, setFullName ] = useState('Your Name');
    
    const { partyName } = props;

    useEffect(() => {
        if (partyName) {
            setFullName(partyName);
        }
    }, [partyName])
    

    return (
        <div className="subscription-agreement">

            <div className="subscription-agreement-document"> 

                <h4>Followne Inc.</h4>
                <h3 style={{lineHeight: '1.2', marginBottom: '2rem'}}>MUTUAL NON-DISCLOSURE AGREEMENT</h3>
                
                <p>This agreement is made on: {moment().format('MM-DD-YYYY')} </p>
                
                <br />
                <p><strong>BETWEEN</strong></p>
                <p>1. Followone, Inc. ; and</p>
                <p>2. {fullName}</p>
                
                <br />            
                <p><strong>RECITALS</strong></p>
                <p>Each undersigned party (the "Receiving Party") understands and acknowledges that the other party (the "Disclosing Party") has disclosed or may disclose information relating to Followone, Inc. which to the extent previously, presently, or subsequently disclosed to the Receiving Party is hereinafter referred to as "Proprietary Information" of the Disclosing Party.</p>
                
                <br />
                <p><strong>OPERATIVE PROVISIONS</strong></p>
                <ol style={{listStyleType: 'decimal'}}>
                    <li>In consideration of the disclosure of Proprietary Information by the Disclosing Party, the Receiving Party hereby agrees: (i) to hold the Proprietary Information in strict confidence and to take all reasonable precautions to protect such Proprietary Information (including, without limitation, all precautions the Receiving Party employs with respect to its own confidential materials), (ii) not to disclose any such Proprietary Information or any information derived therefrom to any third person, (iii) not to make any use whatsoever at any time of such Proprietary Information except to evaluate internally its relationship with the Disclosing Party, (iv) not to copy or reverse engineer any such Proprietary Information, (v) not to disclose any Proprietary Information to any non-essential employees or outside vendors, and (vi) not retain any Proprietary Information beyond the relationship with the Disclosing Party. The Receiving Party shall procure that its essential employees (employees requiring the disclosure of the Proprietary Information), agents and sub-contractors to whom Proprietary Information is disclosed or who have access to Proprietary Information sign a nondisclosure or similar agreement in content substantially similar to this Agreement.</li> 
                    
                    <li>Without granting any right or license, the Disclosing Party agrees that the foregoing shall not apply with respect to any information after five years following the disclosure thereof or any information that the Receiving Party can document (i) is or becomes (through no improper action or inaction by the Receiving Party or any affiliate, agent, consultant or employee) generally available to the public, or (ii) was in its possession or known by it prior to receipt from the Disclosing Party as evidenced in writing, except to the extent that such information was unlawfully appropriated, or (iii) was rightfully disclosed to it by a third party, or (iv) was independently developed without use of any Proprietary Information of the Disclosing Party. The Receiving Party may make disclosures required by law or court order provided the Receiving Party uses diligent reasonable efforts to limit disclosure and has allowed the Disclosing Party to seek a protective order.</li>

                    <li>Immediately upon the termination of the relationship or by written request by the Disclosing Party at any time, the Receiving Party will return to the Disclosing Party all Proprietary Information and all documents or media containing any such Proprietary Information and any and all copies or extracts thereof, save that where such Proprietary Information is a form incapable of return or has been copied or transcribed into another document, it shall be destroyed or erased, as appropriate.</li>

                    <li>The Receiving Party understands that nothing herein (i) requires the disclosure of any Proprietary Information or (ii) requires the Disclosing Party or Receiving Party to proceed with any transaction or relationship.</li>
                    
                    <li>Each party further acknowledges and confirms to the other party that no representation or warranty, express or implied, is or will be made, and no responsibility or liability is or will be accepted by either party, or by any of its respective directors, officers, employees, agents or advisers, as to, or in relation to, the accuracy of completeness of any Proprietary Information made available to the other party or its advisers; it is responsible for making its own evaluation of such Proprietary Information.</li>

                    <li>The failure of either party to enforce its rights under this Agreement at any time for any period shall not be construed as a waiver of such rights. If any part, term or provision of this Agreement is held to be illegal or unenforceable neither the validity, nor enforceability of the remainder of this Agreement shall be affected. Neither Party shall assign or transfer all or any part of its rights under this Agreement without the express written consent of the other Party. This Agreement may not be amended for any other reason without the prior written agreement of both Parties. This Agreement constitutes the entire understanding between the Parties relating to the subject matter hereof unless any representation or warranty made about this Agreement was made fraudulently and, save as may be expressly referred to or referenced herein, supersedes all prior representations, writings, negotiations or understandings with respect hereto.</li>
                    
                    <li>This Agreement shall be governed by the laws of the State of Washington.  Any disputes arising out of this relationship shall be adjudicated by binding private Arbitration through JAMS or AAA.</li>
                    
                    <li>The non-breaching party shall be able to recover all court costs and arbitration fees associated with adjudicating any disputes as well as all reasonable attorneys fees.</li>
                    
                    <li>Due to the nature of the Proprietary Information, the negligent disclosure of Proprietary Information or failure to properly dispose of any Proprietary Information shall carry a reasonable penalty of $250,000.00 per disclosure plus actual and special damages.</li>
                </ol>

                <br />            
                <p><strong>SIGNATURES</strong></p>

                <br />  
                <table>
                    <tbody>
                    <tr>
                        <td><strong>Followone Inc.</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Name:</td>
                        <td>Cristian Popescu</td>
                    </tr>
                    <tr>
                        <td>Title:</td>
                        <td>CEO</td>
                    </tr>
                    <tr>
                        <td>Includes:</td>
                        <td>Business Plan, Mobile Apps, Website</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>777 108th AVE NE, <br/>Bellevue WA, 98004</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>{moment().format('MM-DD-YYYY')}</td>
                    </tr>
                    <tr>
                        <td>Signature:</td>
                        <td style={{fontFamily: 'Reenie Beanie, cursive', fontSize: '3rem'}}>Cristian Popescu</td>
                    </tr>
                    </tbody>
                </table>


                <br />  
                <table>
                    <tbody>
                    <tr>
                        <td><strong>Other Party</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Name:</td>
                        <td>{fullName}</td>
                    </tr>
                    {/* <tr>
                        <td>Title:</td>
                        <td>CEO</td>
                    </tr> */}
                    <tr>
                        <td>Includes:</td>
                        <td>Business Plan, Mobile Apps, Website</td>
                    </tr>
                    {/* <tr>
                        <td>Address:</td>
                        <td>777 108th AVE NE, <br/>Bellevue WA, 98004</td>
                    </tr> */}
                    <tr>
                        <td>Date:</td>
                        <td>{moment().format('MM-DD-YYYY')}</td>
                    </tr>
                    <tr>
                        <td>Signature:</td>
                        <td style={{fontFamily: 'Reenie Beanie, cursive', fontSize: '3rem'}}>{fullName}</td>
                    </tr>
                    </tbody>
                </table>


               
            </div>

            {/* {!props.isCreateAccount &&
                <div className="button-content-actions">
                    <Button onClick={props.next}>I Agree with the Electronic Consent</Button>
                    <Button size="link" onClick={props.close}>I Disagree</Button>
                </div>
            } */}
        </div>
    );
}

export default NdaDocument;