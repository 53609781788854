import React from 'react';
import classes from './InvestmentCard.module.scss';
import numeral from 'numeral';

const RevenueCard = props => {

    return (
        <div className={classes.investmentCard}>
            <h3>Contract Details </h3>
            <table>
                <tbody>
                    <tr>
                        <td>Contract ID</td>
                        <td>{props.contractId}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td className="title-case">{props.status}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{props.name}</td>
                    </tr>
                    <tr>
                        <td>Start Date</td>
                        <td>{props.date}</td>
                    </tr>
                    <tr>
                        <td>End Date</td>
                        <td>{props.endDate}</td>
                    </tr>
                    <tr>
                        <td>Contract Length </td>
                        <td>{props.contractLength} Years</td>
                    </tr>
                    <tr>
                        <td>Monthly Revenue</td>
                        <td>${props.monthlyRevenue}</td>
                    </tr>
                    <tr>
                        <td>Contract Amount</td>
                        <td>${props.contractAmount}</td>
                    </tr>
                </tbody>
            </table>

            <h3 className="rev-nd2-h3">Shares Allocated </h3>
            <table>
                <tbody>
                    <tr>
                        <td>Type of Shares</td>
                        <td className="title-case">{props.shareType}</td>
                    </tr>
                    <tr>
                        <td>Number of Shares</td>
                        <td>{numeral(props.noShares).format('0,0')}</td>
                    </tr>
                    <tr className={classes.equityValue}>
                        <td>$ Gain</td>
                        <td>${numeral((props.noShares * props.currentPricePerShare) - props.contractPrice).format('0,0.00')}</td>
                    </tr>
                    <tr className={classes.equityValue}>
                        <td>Equity Value</td>
                        <td>${numeral(props.noShares * props.currentPricePerShare).format('0,0.00')}</td>
                    </tr>

                    {props.admin && 
                        <tr>
                            <td>Admin Actions</td>
                                <td><a href={`/admin/edit-investment/${props.investmentId}`}>EDIT</a> &nbsp;&nbsp;&nbsp;&nbsp;
                                <button onClick={() => props.deleteTransaction(props.investmentId)}>DELETE</button>
                            </td>
                        </tr>
                    }
                    {/* <tr>
                        <td>Market Share Price</td>
                        <td>${props.marketSharePrice}</td>
                    </tr>
                    <tr>
                        <td>Market Equity Value</td>
                        <td className="green">${props.marketEquityValue}</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default RevenueCard;