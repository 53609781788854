import React from 'react';

const Copyright = props => {

    const year = new Date().getFullYear();

    return (
        <div className="subscription-agreement">

            <div className="subscription-agreement-document">

                <h4>Followne Inc.</h4>
                <h3>COPYRIGHT POLICY</h3>

                <p>© 2014 – {year} Followone Inc. All Rights Reserved</p>

                <p>Except as permitted by the copyright law applicable to you, you may not reproduce or communicate any of the content on this website, including files downloadable from this website, without the permission of the copyright owner.</p>
            </div>
        </div>
    );
}

export default Copyright;