import React, { useState } from 'react';
import LoggedInHeader from '../../shared/components/Navigation/LoggedInHeader';
import './ProfileSettings.scss';

import UpdateAccountPassword from './Components/UpdateAccountPassword';
import UpdateAccreditationStatus from './Components/UpdateAccreditationStatus';
import UpdatePersonalInfo from './Components/UpdatePersonalInfo';
import UpdateBusinessInfo from './Components/UpdateBusinessInfo';
import UpdateProfileImage from './Components/UpdateProfileImage';
import UpdateAccountDocs from './Components/UpdateAccountDocs';

const ProfileSettings = props => {

    const [toggleBox, setToggleBox] = useState({
        profileImage: true,
        accountDocs: false,
        accountBox: false,
        accreditationBox: false,
        personalBox: false,
        businessBox: false,
    });

    const openBox = (box1, box2, box3, box4, box5, box6) => {
        setToggleBox({ [box1]: true, [box2]: false, [box3]: false, [box4]: false, [box5]: false, [box6]: false })
    }

    const boxClasses = (type) => {
        return `profile-settings-box profile-settings-box-${type ? 'open' : 'closed'}`
    }

    const buttonClasses = (type) => {
        return `create-account-content-box-label ${type ? 'create-account-content-box-label-active' : null}`
    }

    const arrrowDir = (type) => {
        return `fal fa-chevron-${type ? 'up' : 'down'}`
    }

    return (
        <div className="profile-settings">
            <LoggedInHeader />

            <h4>Profile Settings</h4>

            <div className="create-account-content-box">

                <button className={buttonClasses(toggleBox.profileImage)} onClick={() => openBox('profileImage', 'accountDocs', 'accountBox', 'accreditationBox', 'personalBox', 'businessBox')}>
                    <i className={arrrowDir(toggleBox.profileImage)}></i> Update Profile Image
                </button>

                <div className={boxClasses(toggleBox.profileImage)}>
                    <UpdateProfileImage />
                </div>

                <button className={buttonClasses(toggleBox.accountDocs)} onClick={() => openBox('accountDocs', 'profileImage', 'accountBox', 'accreditationBox', 'personalBox', 'businessBox')}>
                    <i className={arrrowDir(toggleBox.accountDocs)}></i> Update Account Docs
                </button>

                <div className={boxClasses(toggleBox.accountDocs)}>
                    <UpdateAccountDocs />
                </div>

                <button className={buttonClasses(toggleBox.accountBox)} onClick={() => openBox('accountBox', 'profileImage', 'accountDocs', 'accreditationBox', 'personalBox', 'businessBox')}>
                    <i className={arrrowDir(toggleBox.accountBox)}></i> Update Account Password
                </button>

                <div className={boxClasses(toggleBox.accountBox)}>
                    <UpdateAccountPassword />
                </div>

                <button className={buttonClasses(toggleBox.accreditationBox)} onClick={() => openBox('accreditationBox', 'profileImage', 'accountDocs', 'accountBox', 'personalBox', 'businessBox')}>
                    <i className={arrrowDir(toggleBox.accreditationBox)}></i> Update You Accreditation Status
                </button>

                <div className={boxClasses(toggleBox.accreditationBox)}>
                    <UpdateAccreditationStatus />
                </div>

                <button className={buttonClasses(toggleBox.personalBox)} onClick={() => openBox('personalBox', 'profileImage', 'accountDocs', 'accreditationBox', 'accountBox', 'businessBox')}>
                    <i className={arrrowDir(toggleBox.personalBox)}></i> Update Your Personal Information
                </button>

                <div className={boxClasses(toggleBox.personalBox)}>
                    <UpdatePersonalInfo />
                </div>

                <button className={buttonClasses(toggleBox.businessBox)} onClick={() => openBox('businessBox', 'profileImage', 'accountDocs', 'accreditationBox', 'accountBox', 'personalBox')}>
                    <i className={arrrowDir(toggleBox.businessBox)}></i> Update Your Business Information
                </button>

                <div className={boxClasses(toggleBox.businessBox)}>
                    <UpdateBusinessInfo />
                </div>
            </div >
        </div >
    );
}

export default ProfileSettings;