import React, { useContext, useState, useEffect } from 'react';
import Input from '../../../shared/components/UIElements/Input';
import Button from '../../../shared/components/UIElements/Button';
import ErrorModal from '../../../shared/components/Modals/Error';
import { VALIDATOR_REQUIRE } from '../../../shared/util/validators';
import { useHistory } from 'react-router-dom';
import './AddInvestment.scss';
import { useParams } from 'react-router';
import moment from 'moment';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useForm } from '../../../shared/hooks/form-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import LoggedInHeader from '../../../shared/components/Navigation/LoggedInHeader';


const AddInvestment = props => {

    const history = useHistory();
    const [isEdit, setIsEdit] = useState(false);    
    const auth = useContext(AuthContext);
    const { error, sendRequest, clearError } = useHttpClient();
    const { investmentId } = useParams();

    const [elConsIsChecked, setElConsIsChecked] = useState(false);
    const [policiesIsChecked, setPoliciesIsChecked] = useState(false);
    const [digSignIsChecked, setDigSignIsChecked] = useState(false);
    const [isPaidChecked, setIsPaidChecked] = useState(false);
    const [loadedTransaction, setLoadedTransaction] = useState();

    const [buyer, setBuyer] = useState();
    const [app, setApp] = useState();
    const [companyId, setCompanyId] = useState();
    const [users, setUsers] = useState()

    useEffect(() => {
        if (investmentId) {
            setIsEdit(true);
        }

        if (investmentId) {
            const fetchTransaction = async () => {
                try {
                    const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions/' + investmentId);
                    setLoadedTransaction(responseData.data.data);
                } catch (err) {
                    console.log(err);
                }
            }

            fetchTransaction()
        }
    }, [investmentId, sendRequest])



    useEffect(() => {
        const fetchApp = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/app')
                setApp(responseData.data.data);
            } catch (err) {
                console.log(err);
            }

        }

        fetchApp();

        const fetchCompany = async () => {
            try {
                const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users')
                const fetchedUsers = responseData.data.data;

                const company = fetchedUsers.find(user => user.role === 'company');

                setUsers(fetchedUsers);
                setCompanyId(company._id);

            } catch (err) {
                console.log(err);
            }
        }
        fetchCompany();
        
    }, [sendRequest]) 




    const [formState, inputHandler] = useForm({
        typeOfTransaction: {
            value: '',
            isValid: false
        },
        userSeller: {
            value: '',
            isValid: false
        },
        userBuyer: {
            value: '',
            isValid: false
        },
        typeOfInvestment: {
            value: '',
            isValid: false
        },
        typeOfShare: {
            value: '',
            isValid: false
        },
        contractId: {
            value: '',
            isValid: false
        },
        transferId: {
            value: '',
            isValid: false
        },
        round: {
            value: '',
            isValid: false
        },
        noOfShares: {
            value: '',
            isValid: false
        },
        pricePerShare: {
            value: '',
            isValid: false
        },
        paymentType: {
            value: '',
            isValid: false
        },
        isPaid: {
            value: '',
            isValid: false
        },
        transactionDate: {
            value: '',
            isValid: false
        },
        transactionStatus: {
            value: '',
            isValid: false
        },
        saleEligibilityDate: {
            value: '',
            isValid: false
        },
        revContractEndDate: {
            value: '',
            isValid: false
        },
        revContractLength: {
            value: '',
            isValid: false
        },
        revContractPerMonth: {
            value: '',
            isValid: false
        },
        agreeElectronicConsent: {
            value: '',
            isValid: false
        },
        agreePolicies: {
            value: '',
            isValid: false
        },
        agreeToSignature: {
            value: '',
            isValid: false
        }
    }, false);



   
    const getBuyerData = async (e) => {
        e.preventDefault();

        const buyerId = e.target.value;

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${buyerId}`)
            setBuyer(responseData.data.data);
        } catch (err) {
            console.log(err);
        }
    }


    const addInvestmentSubmitHandler = async event => {
        event.preventDefault();

        let signature;
        let subscriptionAgreement = {};

        if (isEdit) {
            // signature = `${buyer.firstName} ${buyer.lastName}`
            subscriptionAgreement = loadedTransaction.subscriptionAgreement;
        } else {

            signature = `${buyer.firstName} ${buyer.lastName}`

            subscriptionAgreement = {
                noOfShares: formState.inputs.noOfShares.value,
                pricePerShare: formState.inputs.pricePerShare.value,
                transactionPrice: formState.inputs.pricePerShare.value * formState.inputs.noOfShares.value,
    
                bankName: app[0].paymentWireTransfer.bankName,
                bankAba: app[0].paymentWireTransfer.aba,
                bankAccount: app[0].paymentWireTransfer.account,
                bankAccountName: app[0].paymentWireTransfer.accountName,
    
                checkTo: app[0].paymentAddress.to,
                checkAddress: app[0].paymentAddress.street,
                checkCity: app[0].paymentAddress.city,
                checkZip: app[0].paymentAddress.zip,
                checkState: app[0].paymentAddress.state,
    
                accreditationStatus: buyer.accreditationStatus,
                subscriberType: buyer.subscriberType,
                subscriberEntityFormData: buyer.subscriberEntityFormData,
                purposeOfSubscriptionTrue: buyer.purposeOfSubscriptionTrue,
                purposeOfSubscriptionFalse: buyer.purposeOfSubscriptionFalse,
    
                dated: formState.inputs.transactionDate.value,
                buyerSignature: signature,
                buyerName: signature,
                buyerAddressLine1: buyer.homeAddress,
                buyerAddressLine2: buyer.homeAddressLine2,
                buyerCity: buyer.homeCity,
                buyerZip: buyer.homeState,
                buyerState: buyer.homeZip,
    
                buyerBusinessName: buyer.businessName,
                buyerTitle: buyer.jobTitle,
                buyerBusAddressLine1: buyer.businessAddress,
                buyerBusAddressLine2: buyer.businessAddressLine2,
                buyerBusCity: buyer.businessCity,
                buyerBusZip: buyer.businessZip,
                buyerBusState: buyer.businessState,
                buyerBusPhone: buyer.businessPhone,
                buyerBusEIN: buyer.businessEIN,
                buyerBusEmail: buyer.businessEmail,
    
                buyerPhoneNo: buyer.cellularNo,
                buyerSSN: buyer.socialSecurity,
                emailAddress: buyer.email,
    
                acceptanceDate: formState.inputs.transactionDate.value,
                sellerSignature: 'Cristian Popescu',
                sellerName: 'Cristian Popescu',
                sellerTitle: 'CEO'
            }
        }

        

        try {

            if (!isEdit) {
                await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions', 'POST', JSON.stringify({
                    typeOfTransaction: formState.inputs.typeOfTransaction.value,
                    userSeller: formState.inputs.userSeller.value,
                    userBuyer: formState.inputs.userBuyer.value,
                    typeOfInvestment: formState.inputs.typeOfInvestment.value,
                    typeOfShare: formState.inputs.typeOfShare.value,
                    contractId: formState.inputs.contractId.value,
                    transferId: formState.inputs.transferId.value,
                    round: formState.inputs.round.value,
                    noOfShares: formState.inputs.noOfShares.value,
                    pricePerShare: formState.inputs.pricePerShare.value,
                    paymentType: formState.inputs.paymentType.value,
                    transactionPrice: formState.inputs.pricePerShare.value * formState.inputs.noOfShares.value,
                    transactionDate: formState.inputs.transactionDate.value,
                    transactionStatus: formState.inputs.transactionStatus.value,
                    saleEligibilityDate: formState.inputs.saleEligibilityDate.value,
                    revContractEndDate: formState.inputs.revContractEndDate.value,
                    revContractLength: formState.inputs.revContractLength.value,
                    revContractPerMonth: formState.inputs.revContractPerMonth.value,
                    revContractAmount: (formState.inputs.revContractLength.value * 12) * formState.inputs.revContractPerMonth.value * 1,
                    agreeElectronicConsent: elConsIsChecked,
                    agreePolicies: policiesIsChecked,
                    agreeToSignature: digSignIsChecked,
                    isPaid: isPaidChecked,
                    subscriptionAgreement: subscriptionAgreement,
                }), {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`
                })
            } else {
                await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions/' + loadedTransaction._id, 'PATCH', JSON.stringify({
                    typeOfTransaction: formState.inputs.typeOfTransaction.value,
                    userSeller: formState.inputs.userSeller.value,
                    userBuyer: formState.inputs.userBuyer.value,
                    typeOfInvestment: formState.inputs.typeOfInvestment.value,
                    typeOfShare: formState.inputs.typeOfShare.value,
                    contractId: formState.inputs.contractId.value,
                    transferId: formState.inputs.transferId.value,
                    round: formState.inputs.round.value,
                    noOfShares: formState.inputs.noOfShares.value,
                    pricePerShare: formState.inputs.pricePerShare.value,
                    paymentType: formState.inputs.paymentType.value,
                    transactionPrice: formState.inputs.pricePerShare.value * formState.inputs.noOfShares.value,
                    transactionDate: formState.inputs.transactionDate.value,
                    transactionStatus: formState.inputs.transactionStatus.value,
                    saleEligibilityDate: formState.inputs.saleEligibilityDate.value,
                    revContractEndDate: formState.inputs.revContractEndDate.value,
                    revContractLength: formState.inputs.revContractLength.value,
                    revContractPerMonth: formState.inputs.revContractPerMonth.value,
                    revContractAmount: (formState.inputs.revContractLength.value * 12) * formState.inputs.revContractPerMonth.value * 1,
                    agreeElectronicConsent: elConsIsChecked,
                    agreePolicies: policiesIsChecked,
                    agreeToSignature: digSignIsChecked,
                    isPaid: isPaidChecked,
                    subscriptionAgreement: subscriptionAgreement,
                }), {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`
                })
            }
            


            

            history.push('/admin/transactions');
        } catch (err) {
            console.log(err);
        }
    }


    if (isEdit && !loadedTransaction) {
        return null;
    }


    if (!users) {
        return null;
    }


    return (
        <>
            {isEdit && <LoggedInHeader /> }

            <ErrorModal error={error} onClear={clearError} />
            {app && (
                <form className="admin-add-investment" onSubmit={addInvestmentSubmitHandler}>

                    <h4>{isEdit ? 'Edit' : 'Add'} Investment</h4>

                    <div className="row">
                        <Input
                            id="contractId"
                            type="text"
                            element="input"
                            label="Contract ID"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            initialValue={loadedTransaction ? loadedTransaction.contractId : ''}
                        />
                        <Input
                            id="transferId"
                            type="text"
                            element="input"
                            label="Transfer ID"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            initialValue={loadedTransaction ? loadedTransaction.transferId : ''}
                        />

                        <Input
                            id="transactionDate"
                            type="date"
                            element="input"
                            label="Transaction Date"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid transaction date."
                            initialValue={loadedTransaction ? moment(loadedTransaction.transactionDate).utc().format('YYYY-MM-DD') : ''}
                        />

                        <Input
                            id="typeOfTransaction"
                            element="select"
                            label="Select Type of Transaction"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select the type of transaction."
                            initialValue={loadedTransaction ? loadedTransaction.typeOfTransaction : ''}
                        >
                            <option value="select-transaction-type">Choose one...</option>
                            <option value="investment">Investment</option>
                            <option value="bonus">Bonus</option>
                            <option value="employee">Employee</option>
                        </Input>

                        <Input
                            id="transactionStatus"
                            element="select"
                            label="Transaction Status"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select transaction status."
                            initialValue={loadedTransaction ? loadedTransaction.transactionStatus : ''}
                        >
                            <option value="select-investment-type">Choose one...</option>
                            <option value="pending">Pending</option>
                            <option value="in-progress">In Progress</option>
                            <option value="completed">Completed</option>
                            <option value="declined">Declined</option>
                        </Input>
                    </div>

                    <h4>Transaction Users</h4>

                    <div className="row">
                        {/* <Input
                            id="userSeller"
                            type="text"
                            element="input"
                            label="#Key Seller"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid seller id."
                            initialValue={loadedTransaction ? loadedTransaction.userSeller : ''}
                        /> */}

                        <Input
                            id="userSeller"
                            element="select"
                            label="#Key Seller"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid seller id."
                            initialValue={loadedTransaction ? loadedTransaction.userSeller : ''}
                        >
                            <option value="select-investment-type">Choose Seller...</option>
                            {users.map(u => {
                                return <option key={u._id} value={u._id}>{u.firstName} {u.lastName}</option>
                            })}
                        </Input>

                        {/* <Input
                            id="userBuyer"
                            type="text"
                            element="input"
                            label="#Key Buyer"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            actionChange={getBuyerData}
                            errorText="Please enter a valid buyer id."
                            initialValue={loadedTransaction ? loadedTransaction.userBuyer : ''}
                        /> */}

                        <Input
                            id="userBuyer"
                            element="select"
                            label="#Key Buyer"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            actionChange={getBuyerData}
                            errorText="Please enter a valid buyer id."
                            initialValue={loadedTransaction ? loadedTransaction.userBuyer : ''}
                        >
                            <option value="select-investment-type">Choose Buyer...</option>
                            {users.map(u => {
                                return <option key={u._id} value={u._id}>{u.firstName} {u.lastName}</option>
                            })}
                        </Input>
                    </div>

                    <p style={{ marginBottom: '4rem' }}>Followone Key #: {companyId}</p>

                    <h4>Transaction Details</h4>


                    <div className="row">
                        <Input
                            id="typeOfInvestment"
                            element="select"
                            label="Select Type of Investment"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select type of investment."
                            initialValue={loadedTransaction ? loadedTransaction.typeOfInvestment : ''}
                        >
                            <option value="select-investment-type">Choose one...</option>
                            <option value="equity">Equity</option>
                            <option value="revenue">Revenue</option>
                        </Input>

                        <Input
                            id="typeOfShare"
                            element="select"
                            label="Select Type of Shares"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select type of share."
                            initialValue={loadedTransaction ? loadedTransaction.typeOfShare : ''}
                        >
                            <option value="select-share-type">Choose one...</option>
                            <option value="options">Options</option>
                            <option value="common">Common</option>
                            <option value="preferred">Preferred</option>
                            <option value="restricted">Restricted</option>
                        </Input>
                    </div>


                    <h4>Shares Information</h4>

                    <div className="row">
                        <Input
                            id="round"
                            element="select"
                            label="Select Round"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select round."
                            initialValue={loadedTransaction ? loadedTransaction.round : ''}
                        >
                            <option value="select-round">Choose one...</option>
                            <option value="founders-investment">Founders Investment</option>
                            <option value="seed-investment">Seed Investment</option>
                            <option value="family-friends">Family & Friends</option>
                            <option value="series-a">Series A</option>
                            <option value="series-b">Series B</option>
                            <option value="series-c">Series C</option>
                            <option value="series-d">Series D</option>
                            <option value="series-e">Series E</option>
                        </Input>

                        <Input
                            id="noOfShares"
                            type="number"
                            element="input"
                            label="No of Shares"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter the number of shares."
                            initialValue={loadedTransaction ? loadedTransaction.noOfShares : ''}
                        />

                        <Input
                            id="pricePerShare"
                            type="number"
                            element="input"
                            label="Price / Share"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter the price per share."
                            initialValue={loadedTransaction ? loadedTransaction.pricePerShare : ''}
                        />

                        <Input
                            id="paymentType"
                            element="select"
                            label="Select Payment Type"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please select payment type."
                            initialValue={loadedTransaction ? loadedTransaction.paymentType : ''}
                        >
                            <option value="select-payment-type">Choose one...</option>
                            <option value="money-order">Money Order</option>
                            <option value="checks">Checks</option>
                            <option value="wire-transfer">Wire Transfer</option>
                        </Input>
                    </div>

                    <h4>Eligibility Information</h4>

                    <div className="row">
                        <Input
                            id="saleEligibilityDate"
                            type="date"
                            element="input"
                            label="Sale Eligibility Date"
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a valid sale eligibility date."
                            initialValue={loadedTransaction ? moment(loadedTransaction.saleEligibilityDate).utc().format('YYYY-MM-DD') : ''}
                        />
                    </div>

                    <h4>Revenue Contract Details</h4>


                    {/* --- IF REVENUE --- */}
                    <div className="row">
                        <Input
                            id="revContractEndDate"
                            type="date"
                            element="input"
                            label="Contract End Date"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            initialValue={loadedTransaction ? loadedTransaction.revContractEndDate : ''}
                        />

                        <Input
                            id="revContractLength"
                            element="select"
                            label="Contract Length"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            initialValue={loadedTransaction ? loadedTransaction.revContractLength : ''}
                        >
                            <option value="select-rcl-term">Choose one...</option>
                            <option value="2">2 Years</option>
                            <option value="3">3 Years</option>
                            <option value="5">5 Years</option>
                        </Input>

                        <Input
                            id="revContractPerMonth"
                            element="select"
                            label="Revenue / Month"
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            initialValue={loadedTransaction ? loadedTransaction.revContractPerMonth : ''}
                        >
                            <option value="select-rcpm-month">Choose one...</option>
                            <option value="25000">25000</option>
                            <option value="50000">50000</option>
                            <option value="100000">100000</option>
                        </Input>
                    </div>
                    {/* --- IF REVENUE ENDS --- */}

                    <h4>Agreements</h4>

                    <div className="row">
                        <Input
                            id="agreeElectronicConsent"
                            type="checkbox"
                            element="checkbox"
                            label="Agree to Electronic Consent."
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            align="left"
                            actionChange={(event) => setElConsIsChecked(event.currentTarget.checked)}
                            initialValue={loadedTransaction ? loadedTransaction.agreeElectronicConsent : ''}
                        />

                        <Input
                            id="agreePolicies"
                            type="checkbox"
                            element="checkbox"
                            label="Agree to Policies."
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            align="left"
                            actionChange={(event) => setPoliciesIsChecked(event.currentTarget.checked)}
                            initialValue={loadedTransaction ? loadedTransaction.agreePolicies : ''}
                            // checked={isChecked}
                        />

                        <Input
                            id="agreeToSignature"
                            type="checkbox"
                            element="checkbox"
                            label="Agree to Digital Signature."
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            align="left"
                            actionChange={(event) => setDigSignIsChecked(event.currentTarget.checked)}
                            initialValue={loadedTransaction ? loadedTransaction.agreeToSignature : ''}
                            // checked={isChecked}
                        />

                        <Input
                            id="isPaid"
                            type="checkbox"
                            element="checkbox"
                            label="Transaction is Paid."
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={true}
                            align="left"
                            actionChange={(event) => setIsPaidChecked(event.currentTarget.checked)}
                            initialValue={loadedTransaction ? loadedTransaction.isPaid : ''}
                            // checked={isChecked}
                        />
                    </div>

                    <div className="button-holder">
                        <Button disabled={!formState.isValid && !isEdit} type="submit">{isEdit ? 'Save' : 'Add'} Investment</Button>
                    </div>
                </form>
            )}
        </>
    )
}

export default AddInvestment;