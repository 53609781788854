import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../UIElements/Input';
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from '../../util/validators';
import { useForm } from '../../hooks/form-hook';
import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/Modals/Error';
import Button from '../../components/UIElements/Button';

const LogInModal = props => {

    let history = useHistory();
    const auth = useContext(AuthContext);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const [formState, inputHandler] = useForm({
        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async (event) => {
        event.preventDefault();


        try {
            const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/users/login', 'POST', JSON.stringify({
                email: formState.inputs.email.value,
                password: formState.inputs.password.value
            }), {
                'Content-Type': 'application/json'
            });

            auth.login(responseData.data.user._id, responseData.token, responseData.data.user);
            history.push('/dashboard');

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>
            {error && <ErrorModal error={error} onClear={clearError} />}

            {isLoading && <LoadingSpinner asOverlay />}

            <form onSubmit={authSubmitHandler}>
                <Input
                    id="email"
                    element="input"
                    type="email"
                    label="Email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email address."
                    onInput={inputHandler}
                />
                <Input
                    id="password"
                    element="input"
                    type="password"
                    label="Password"
                    validators={[VALIDATOR_MINLENGTH(8)]}
                    errorText="Please enter a valid password, at least 8 characters."
                    onInput={inputHandler}
                />

                <div className="button-content-actions">
                    <Button type="submit" disabled={!formState.isValid}>Login</Button>
                    <Button color="white" type="button" onClick={props.openForgotPass}>Forgot Password</Button>
                </div>
            </form>
        </>
    );
}

export default LogInModal;