// import React, { useEffect, useState } from 'react';
import classes from './DashboardSidebar.module.scss';
import Button from '../../../shared/components/UIElements/Button';
// import { useHttpClient } from '../../../shared/hooks/http-hook';

// import numeral from 'numeral';
import { useHistory } from 'react-router-dom';

const DashboardSidebar = props => {

    const { app, user, offer } = props;
    const history = useHistory();
    // const { sendRequest } = useHttpClient();
    // const [amountRaised, setAmountRaised] = useState(0);

    // useEffect(() => {
    //     const fetchTransactions = async () => {
    //         const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/transactions');

    //         let totalAmountRaised = 0;
        
    //         responseData.data.data.forEach(t => {
    //              if ((t.round === `series-${offer.series.toLowerCase()}`) && t.isPaid === true) {
    //                 totalAmountRaised = totalAmountRaised + (t.noOfShares  * t.pricePerShare);
    //              }
    //         })

    //         setAmountRaised(totalAmountRaised)
    //     }

    //     fetchTransactions();
    // },[sendRequest, offer])

    const onUpdateDocuments = () => {
        history.push('/verification-process')
    }

    if (!app || !user || !offer) return null;

    return (
        <div className={classes.accountPageSidebar}>


            {(!user.uploadId || user.uploadId === "") || (!user.uploadAccrediationDoc || user.uploadAccrediationDoc === "") ? (
                <div className={classes.accountPageSidebarBox}>
                    <div className={classes.sidebarActionBox}>
                        <i className="fal fa-shield-check fa-shield-check-unverified"></i>
                        <p>Complete the accredited<br /> investor verification process.</p>
                        <Button color="red" onClick={onUpdateDocuments}>Update Documents</Button>
                    </div>
                </div> 
            ) : null}
                  

            {/* <div className={classes.accountPageSidebarBox}>
                <h4>Your Account Summary</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Account #</td>
                            <td>{user.accountId}</td>
                        </tr>
                        <tr>
                            <td>Total Shares</td>
                            <td>{numeral(user.totalShares).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Equity Value</td>
                            <td>${numeral(user.totalShares * offer.priceShare).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Shares Available<br /> for Sale</td>
                            <td>{numeral(user.forSaleShares).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Sold Shares</td>
                            <td>{numeral(user.soldShares).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Total Money Earned</td>
                            <td>${numeral(user.moneyEarned).format('0,0.00')}</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{ marginTop: "1rem" }}>
                    <Button to="/buy-shares">Buy Shares</Button>
                    <Button color="disabled">Sell Shares</Button>
                </div>

                <p className={classes.note}><i className="fal fa-exclamation-triangle"></i> You will be able to sell shares in 365 days.</p>
            </div> */}

            {/* <div className={classes.accountPageSidebarBox}>
                <h4>Followone (Fo)<br />Offer to Purchase Stock </h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Series</td>
                            <td>{offer.series}</td>
                        </tr>
                        <tr>
                            <td>Round</td>
                            <td>{offer.round}</td>
                        </tr>
                        <tr>
                            <td>Max</td>
                            <td>${numeral(offer.max).format('0,0.00')}</td>
                        </tr>

                        <tr>
                            <td>Min</td>
                            <td>${numeral(offer.min).format('0,0.00')}</td>
                        </tr>
                        <tr>
                            <td>Price / Share</td>
                            <td>${numeral(offer.priceShare).format('0,0.00')}</td>
                        </tr>
                        <tr>
                            <td>Total Shares Issued</td>
                            <td>{numeral(offer.totalSharesIssued).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Amount Raised To Date</td>
                            <td>${numeral(amountRaised).format('0,0')}</td>
                        </tr>
                        <tr>
                            <td>Amount Remaining</td>
                            <td>${numeral(offer.max - amountRaised).format('0,0')}</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <Button color="green" to="/buy-shares">Buy Shares</Button>
            </div> */}

            {/* <div className={classes.accountPageSidebarBox}>
                <h4>(FO) Stock Summary</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Price/Share</td>
                            <td>${numeral(offer.priceShare).format('0,0.00')}</td>
                        </tr>
                        <tr>
                            <td>Market Cap.</td>
                            <td>${numeral(app.outstandingCommonShares * offer.priceShare).format('0,0')}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}

            <div className={classes.accountPageSidebarBox}>
                <div className={classes.sidebarActionBox}>
                    <i className="fal fa-user fa-user-check-verified"></i>
                    <p>Update<br /> Your Profile</p>
                    <Button to="/profile-settings">Profile Settings</Button>
                </div>
            </div>
        </div >
    );

}

export default DashboardSidebar;